import React, { Component } from 'react';
import {  View, TouchableOpacity,AsyncStorage,Switch,Image, Dimensions } from 'react-native';
import { Container, Button, Content, Thumbnail, Text, Icon,  Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import {  ButtonGroup  } from 'react-native-elements';
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'
import Moment from 'moment';
import TimeAgo from 'react-native-timeago';
import { Audio } from 'expo-av';

import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';



class BarOrders extends Component {
    static navigationOptions = {
        headerShown: false,
        title: 'Order Dashboard',
      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          bartoken:'',
          table:[],
          isLoading: true,
          navProps: props.navigation.state.params || {},
          latestOrder:'',
          hasFetched:false,
          restaurant:[],
          sort:'Newest',
          awaitingFood:true,
          awaitingDrink:true,
          soundAlerts:'on',
          selectedIndex:0,
          orderCount:0,
          filter:'pending',
          isAcceptingOrder:false,
        };
      }

      getToken = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            // Fetch the bartoken to see if there is an authenticated bar.
            if (tokenName === 'soundAlerts') {
                   this.setState({soundAlerts: tokenValue})  
              }
          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
      changePage = (page) => {
        //alert(page)
    }
      
      fetchOrders = (status, newOrder = false) => {
        this.setState({ isLoading: true });
        fetch('https://tabledrop.app/api/barorders/?status=' + status + '&drinks=' + this.state.awaitingDrink + '&food=' + this.state.awaitingFood + '&sort=' + this.state.sort , {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.props.bartoken
          }
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({ data: json });
          this.setState({ isLoading: false });
          this.setState({ orderCount: json.length });
      })
        .catch((error) => console.error(error))
        .finally(() => {
          this.setState({ isLoading: false });
          this.setState({ hasFetched: true });

          if (newOrder && this.state.soundAlerts == 'on') {
            this.playAlert();
          }


        });

        if (this._interval) { clearInterval(this._interval) }
        if (this.state.filter == 'pending') {
            this._interval = setInterval(() => {
                    this.getLatestOrder();
            }, 15000);
          }


      }

      playAlert = async () =>
      {
          const soundObject = new Audio.Sound();
          try {
              await soundObject.loadAsync(require('../assets/alert.mp3'));
              await soundObject.playAsync();
              //alert('s')
              // Your sound is playing!
            } catch (error) {
              alert(error)
            // An error occurred!
          }
      }




      getOrientation = () =>
      {

             if( Dimensions.get('screen').width < Dimensions.get('screen').height )
            {
              this.setState({ screenOrientation: 'portrait' });
            }
            else
            {
              this.setState({ screenOrientation: 'landscape' });
            }

      }



      updateOrder = (id, status) => {
        fetch('https://tabledrop.app/api/barorders/' + id + '/', {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.props.bartoken
          },
          body: JSON.stringify({
              id: id,
              status: status
          })
      })
        .then((response) => response.json())
        .then((json) => {
              //do something with response 'json'
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.fetchOrders(this.state.filter)
        });
      }





      componentDidMount() {
        this.getToken("soundAlerts");


        if (this.state.navProps || this.state.filter) {

            let filter = this.state.navProps.filter || this.state.filter

            if (filter == 'pending') {
              this.setState({filter:'pending'})
              this.setState({selectedIndex: 0})
            }
            if (filter == 'complete') {
              this.setState({filter:'complete'})
              this.setState({selectedIndex: 1})
            }
            if (filter == 'cancelled') {
              this.setState({filter:'cancelled'})
              this.setState({selectedIndex: 2})
            }
            if (filter == 'today') {
              this.setState({filter:'today'})
              this.setState({selectedIndex: 3})
            }


      }




          fetch('https://tabledrop.app/api/managerestaurant/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.bartoken
            }
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({restaurant: json[0]})
            this.props.selectBar(json[0]);

          })

          this.getLatestOrder();

          this.getOrientation();

          this.changeListener = Dimensions.addEventListener( 'change', () =>
          {
            this.getOrientation();
          });



          this.focusListener = this.props.navigation.addListener("didFocus", () => {
            this.getToken("soundAlerts");

                if (this.state.navProps && (this.state.navProps == 'pending' && this.state.filter != 'pending')) {
                  if (this.state.navProps.filter) {
                      this.updateIndex('__notset__',this.state.navProps.filter);
                  }
                } else {
                    if (this.state.filter) {
                      this.fetchOrders(this.state.filter);

                    }else {
                      this.fetchOrders('pending');
                    }

                }


              } );


      }


     getLatestOrder() {
        fetch('https://tabledrop.app/api/latestorder/', {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.props.bartoken
          }
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.id > this.state.latestOrder && this.state.hasFetched) {
            this.fetchOrders('pending',true);

          }
          this.setState({latestOrder:json.id})


        })

      }




      componentWillUnmount() {
        clearInterval(this._interval);
        this.focusListener.remove();
        //this.changeListener.remove();

      }


      setActiveTabOnly = (selectedIndex = null, filter = '') => {
        if (selectedIndex == 0 || filter == 'pending') {
          this.setState({filter:'pending'})
          this.setState({selectedIndex: 0})

        }
        if (selectedIndex == 1 || filter == 'complete') {
          this.setState({filter:'complete'})
          this.setState({selectedIndex: 1})
        }
        if (selectedIndex == 2 || filter == 'cancelled') {
          this.setState({filter:'cancelled'})
          this.setState({selectedIndex: 2})
        }
        if (selectedIndex == 3 || filter == 'today') {
          this.setState({filter:'today'})
          this.setState({selectedIndex: 3})
        }
      }


      updateIndex = (selectedIndex = null, filter = '') => {
          if (selectedIndex == 0 || filter == 'pending') {
            this.setState({filter:'pending'}, function () {
              this.fetchOrders('pending');
            })
            this.setState({selectedIndex: 0})

          }
          if (selectedIndex == 1 || filter == 'complete') {
            this.setState({filter:'complete'})
            this.fetchOrders('complete');
            this.setState({selectedIndex: 1})
          }
          if (selectedIndex == 2 || filter == 'cancelled') {
            this.setState({filter:'cancelled'})
            this.fetchOrders('cancelled');
            this.setState({selectedIndex: 2})
          }
          if (selectedIndex == 3 || filter == 'today') {
            this.setState({filter:'today'})
            this.fetchOrders('today');
            this.setState({selectedIndex: 3})
          }
        }

        toggleSwitchDrink = (value) => {
          this.setState({awaitingDrink: !this.state.awaitingDrink}, function () {
              this.setState({isLoading: true});

              this.fetchOrders('pending');
            });
          }
          
        toggleSwitch = (value) => {
          this.setState({awaitingFood: !this.state.awaitingFood}, function () {
              this.setState({isLoading: true});
              this.fetchOrders('pending');
            });
      }

      toggleSort = () => {
        if (this.state.sort == 'Oldest') { 
          this.setState({sort: 'Newest'}, function () {
            this.setState({isLoading: true});
            this.fetchOrders(this.state.filter);
          });

        } else {
          this.setState({sort: 'Oldest'}, function () {
            this.setState({isLoading: true});
            this.fetchOrders(this.state.filter);
          });


        }

    }

      render() {
        Moment.locale('en');
        const { data, isLoading,selectedIndex,awaitingFood,awaitingDrink,isAcceptingOrder,screenOrientation,filter } = this.state;
        const { navigation } = this.props;
        const buttons = ['Pending', 'Delivered', 'Cancelled','Today']

        const toggleSwitch = (previousState) => {
          this.setState({isAcceptingOrder: previousState});
        }


        if (this.state.screenOrientation == 'portrait') {
          return (
                 <View style={{padding:20, flex:1, textAlign: 'center', alignItems:'center', backgroundColor:'white'}}>
                      <Image source={require('../assets/rotate.png')} style={{width:150, height:150 }}/>
                      <Text>Please rotate your device to view the Venue Management Console</Text>
                  </View>
          )


        }

        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
                        
        <Container>
        <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
            <Content>
            <Card transparent>
              <CardItem>
              <Left>
              <Thumbnail source={{uri: this.state.restaurant.image}} />
                  <Body>
                      <Text> {this.state.restaurant.name}</Text>
                      <Text note>Order Console </Text>
                  </Body>
              </Left>
              <Right>
 

              </Right>
              </CardItem>
            </Card>
            <View style={{textAlign: 'right',alignItems:'flex-end'}}>
            <Button info small onPress={this.toggleSort} style={{textAlign:'right',padding:5,marginRight:5,width:200}}>
              <Text>Order by: {this.state.sort}
              </Text>
            </Button>
          </View>
            <ButtonGroup
              selectedIndex={selectedIndex}
              onPress={this.updateIndex}
              buttons={buttons} />

          {(() => {

          if (isLoading) {
              return  (
                    <View style={{flexDirection: 'column', flex:1, textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                  );
            } else {
              return  (
                    <Card>

                      {data.map((item, index) =>
                          <CardItem bordered>
                            <View style={{flex: 1, flexDirection: 'row', justifyContent:'center'}}>
                                  <View style={{ flex:1, height: 95, justifyContent:'center'}}>
                                        <Text>#{item.id}</Text>
                                  </View>
                                  <View style={{ flex:1, height: 95, justifyContent:'center'}}>
                                    
                                  {item.type == 1  &&
                                      <Button onPress={() => this.props.navigation.navigate('ViewOrder',{order:item})} rounded style={{width:115,textAlign:'center',alignItems:'center'}}>
                                        <Text style={{textAlign:'center'}}>   Table {item.tabledetails.number}</Text>
                                      </Button>
                                  }
                                  {item.type == 2 && item.status != 7 &&
                                      <Button info onPress={() => this.props.navigation.navigate('ViewOrder',{order:item})} rounded style={{width:115,textAlign:'center'}}>
                                        <Text style={{textAlign:'center'}}>Collection</Text>
                                      </Button>
                                  }
                                  {item.type == 2 && item.status == 7 &&
                                      <Button success onPress={() => this.props.navigation.navigate('ViewOrder',{order:item})} rounded style={{width:115,textAlign:'center'}}>
                                        <Text style={{textAlign:'center'}}>    Ready</Text>
                                      </Button>
                                  }                                  

                                  </View>
                                  <View style={{ flex:1, height: 95, justifyContent:'center'}}>
                                      <Text>£{item.total_price}</Text>
                                  </View>
                                  <View style={{ flex:1, height: 95,textAlign:'center',flexDirection:'row'}}>
                                     {(item.has_drink > 0 && item.status != '6') &&
                                          <View style={{flex:1,marginTop:40}}>
                                            <Icon style={{ fontSize:12 }} type="FontAwesome5" name='glass-martini'  />
                                          </View>
                                      }
                                    {item.has_food > 0 &&
                                          <View style={{flex:1,marginTop:40}}>
                                            <Icon style={{ fontSize:12 }} type="FontAwesome5" name='utensils'  />
                                          </View>
                                      }
                                  </View>

                                  <View style={{ flex:1, height: 95, justifyContent:'center'}}>
                                      <TimeAgo time={item.created_at}  interval={1000} />
                                  </View>

                                  <View style={{width: 50, flex:2, height: 95,justifyContent:'center'}}>
                                    <Button success onPress={() => this.props.navigation.navigate('ViewOrder',{order:item})}  style={{textAlign:'center',justifyContent:'center',borderRadius:10}}>
                                      <Text>View Order</Text>
                                    </Button>
                                  </View>

                            </View>
                          </CardItem>
                        )}

                          {(() => {
                              if (this.state.orderCount == 0) {
                                  return (
                                    <Text style={{textAlign:'center',padding:20}}>No orders found</Text>
                                  )

                              }
                          })()}
                        </Card>
                    )
                    }
            })()}

              </Content>
              <FooterBar />
            </Container>
            </Drawer>
        );
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant,
      }
  }

    const mapDispatchToProps = (dispatch) => {
      return {
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
      }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(BarOrders)
