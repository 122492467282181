import React, { Component } from 'react';
import { Text,TouchableOpacity  } from 'react-native';
import { Container, Header, Left, Right, Spinner, Content, Icon, List, ListItem } from 'native-base';
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'

class selectTable extends Component {
    static navigationOptions = {
        title: 'Select Your Table',
      };
    constructor(props) {
        super(props);
        this.state = {
          selectedBar: props.navigation.state.params,
          data: [],
          table:[],
          isLoading: true
        };
      }


      fethDetails(id) {
        fetch('https://tabledrop.app/api/table/?restaurant=' + this.state.selectedBar.item.id)
        .then((response) => response.json())
        .then((json) => {
          this.setState({ data: json });
      })
        .catch((error) => console.error(error))
        .finally(() => {
          //this.setState({ isLoading: false });
        });
        fetch('https://tabledrop.app/api/drinkcategory/?restaurant=' + this.state.selectedBar.item.id)
        .then((response) => response.json())
        .then((json) => {
          this.props.setDrinksMenu(json)
      })
        .catch((error) => console.error(error))
        .finally(() => {
        // this.setState({ isLoading: false });
        });

        fetch('https://tabledrop.app/api/foodcategory/?restaurant=' + this.state.selectedBar.item.id)
        .then((response) => response.json())
        .then((json) => {
          this.props.setFoodMenu(json)
      })
        .catch((error) => console.error(error))
        .finally(() => {
          this.setState({ isLoading: false });
        })

      }


      componentDidMount() {
        this.focusListener = this.props.navigation.addListener("didFocus", () => {
          this.fethDetails(this.state.selectedBar.item.id);
       });
   

      }

      componentWillUnmount() {
        // Remove the event listener
        this.focusListener.remove();
      }


      selectTable = (table) => {
        this.props.selectTable(table)
        this.props.navigation.navigate('loadMenu', {item: table,type:'tableservice'})
      }

      render() {
        const { data, isLoading } = this.state;


        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue' />
                </Container>
              );

        } else {
        return (
            <Container>
              <Content>
                <List>
                {data.map((item, index) =>

                <ListItem onPress={() => this.selectTable(item)} >
                  <Left>
                    <Text>Table {item.number}</Text>
                  </Left>
                  <Right>
                    <Icon name="arrow-forward" />
                  </Right>
                </ListItem>

                )}
               </List>
              </Content>
            <FooterBar />
          </Container>
        );
            }
      }
    };


    const mapStateToProps = (state) => {
      return {
          table: state.table,
          
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
        selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table }),
        setDrinksMenu: (drinks) => dispatch({ type: 'SET_DRINKS_MENU', payload: drinks }),
        setFoodMenu: (foods) => dispatch({ type: 'SET_FOOD_MENU', payload: foods }),


          

      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(selectTable)
