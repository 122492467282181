import React, { Component } from 'react';
import {  FlatList,  View, Text,TouchableOpacity,AsyncStorage,ScrollView  } from 'react-native';
import { Container, Thumbnail,  List, ListItem, Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import {connect} from 'react-redux'
import Moment from 'moment';
import FooterBar from '../shared/Footer.js'
import StripeCheckoutSca from '../thirdparty/expo-stripe-checkout-sca';
import { withNavigation } from 'react-navigation'
import Import from '../thirdparty/expo-stripe-checkout-sca/import';


class PaymentScreen extends Component {
    static navigationOptions = {
        title: 'Payment',
        headerLeft: null
      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          props: props.navigation.state.params,

          cid:'',
          token:'',
          table:[],
          isLoading: false,
          modalVisible:true
        };
      }

      async getToken(tokenName) {
        try {
          let userData = await AsyncStorage.getItem(tokenName);
          let data = userData;
          if (tokenName == 'cid') { this.setState({cid: data}) }
          if (tokenName == 'token') { this.setState({token: data}) }
          }
        catch (error) {
          console.log("Something went wrong", error);
          this.setState({ isLoading: false });
        }

      }


      componentDidMount() {
        this.getToken("cid");
        this.getToken("token");

      }



    log(e) {
        let url = e.url;
        // Do your stuff
        // Check url. It may be the success or cancel url
        // Here you can set modal as no more visible
        alert(url)
        if (url.includes('mpayment/success')) {
            this.setState({modalVisible:false})
            this.props.navigation.navigate('myOrders')
        }

      if (url.includes('mpayment/cancel')) {
          this.setState({modalVisible:false})
          this.props.navigation.navigate('myOrders')
      }

    }

      closeModal = () => {
        this.setState({modalVisible:false})
        this.props.navigation.navigate('myOrders')
      }


      onMessage( event ) {
        alert(event.nativeEvent.data);
        console.log( "On Message", event.nativeEvent.data );
    }

    onNavigationStateChange(webViewState){
      console.log(webViewState.url)
      let url = webViewState.url;
      if (url.includes('paycomplete')) {
        this.setState({modalVisible:false})
        this.props.navigation.navigate('myOrders')
    }

    }



      render() {
        const { data, isLoading,modalVisible } = this.state;
        const { navigation } = this.props;
        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue'/>
                </Container>
              );
        } else {

        return (
          <Container>
            <Import.WebView
                source={{
                  uri: 'https://tabledrop.com/mpay/?pi=' + this.state.props.pintent
                }}
                useWebKit={true}
                javaScriptEnabled={true}
                scrollEnabled={true}
                bounces={false}
                originWhitelist={["*"]}
                startInLoadingState={true}
                onNavigationStateChange={(e) => this.onNavigationStateChange(e)}
                  style={{ marginTop: 20 }}
              />



            <FooterBar />
        </Container>
        );
     } // is loading
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
          selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table })
      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(PaymentScreen))
