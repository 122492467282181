import React, { Component } from 'react';
import { Container, Header, Left, Right, Body, Button, Icon, Text  } from 'native-base';
import { withNavigation } from 'react-navigation'
import {connect} from 'react-redux'

import {  View, Image, AsyncStorage,StatusBar } from 'react-native';


class HeaderBar extends Component {
  static navigationOptions = {
    headerShown: false,
};
constructor(props) {
    super(props);
    this.state = {
      data: [],
      cid:'',
      username:'',
      password:'',
      table:[],
      isLoading: true,
      loggedIn:false,
      bartoken:''
    };
  }

  async storeToken(tokenName, tokenValue) {
    try {
       await AsyncStorage.setItem(tokenName, tokenValue);
    } catch (error) {
      console.log("Something went wrong", error);
    }
  }

  logout = () => {
    this.props.setBarToken('');
    this.storeToken('bartoken','');


    //temporary!!!!!!
    //this.storeToken('cid','');



    this.props.navigation.navigate('BarLogin')  ;
  }


  render() {
  return (
      <Header style={{
      backgroundColor: '#1C2B48',
      justifyContent: 'center',
    }}>
            <StatusBar barStyle = "light-content" hidden = {false} backgroundColor = "#1C2B48" translucent = {false}/>

            <Left style={{flex:1}}>  
            
        <Button transparent onPress={()=>this.props.openDrawer()}  >
        <Icon name='menu' />
       </Button>
       </Left>
            <Image
                source={require('../assets/logo.png')}
                style={{width:222, height:42, marginTop: 0}}
            />
          <Right>
          {(() => {
                        if ( this.props.bartoken ) {
                            return (
                                 <Button onPress={() => this.props.navigation.navigate('BarOrders')} transparent>
                                      <Icon name='exit' onPress={this.logout}></Icon>
                                 </Button>
                              )
                            } else {
                            return(
                              <Button onPress={() => this.props.navigation.navigate('BarLogin')}  transparent>
                              <Icon name='lock' />
                            </Button>
                            )
                            }
             })()}
          </Right>
      </Header>)
  }
}



const mapStateToProps = (state) => {
  return {
      bartoken: state.bartoken
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table }),
      setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(HeaderBar))
