import React, { Component } from 'react';
import {  View, AsyncStorage  } from 'react-native';
import { Container,  H2, Button, Content, Item, Textarea, Thumbnail, Text, Icon, List, ListItem, Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'
import Moment from 'moment';
import TimeAgo from 'react-native-timeago';

class BarOrders extends Component {
    static navigationOptions = {
        headerShown: true,
        title: 'View Order Details',

      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          navProps:props.navigation.state.params,
          bartoken:'',
          table:[],
          isLoading: true,
          restaurant:[],
          selectedIndex:0,
          orderCount:0,
          filter:'pending',
          isAcceptingOrder:false,
        };
      }


      getOrder = (id) => {
        fetch('https://tabledrop.app/api/barorder/' + id + '/', {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.state.bartoken
          }
      })
        .then((response) => response.json())
        .then((json) => {
                this.setState({data: json});
                this.setState({ isLoading: false });
            })
        .catch((error) => console.error(error))
        .finally(() => {
                this.setState({ isLoading: false });
        });
      }

      async getToken(tokenName) {
        try {
          let userData = await AsyncStorage.getItem(tokenName);
          let data = userData;
          this.setState({bartoken: data})
          this.getOrder(this.state.navProps.order.id)
        } catch (error) {
          console.log("Something went wrong", error);
          this.setState({ isLoading: false });
        }
      }

      componentDidMount() {
        this.getToken("bartoken");
      }


      updateOrder = (id, status) => {
        this.setState({isLoading: true})
        fetch('https://tabledrop.app/api/barorders/' + id + '/', {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.props.bartoken
          },
          body: JSON.stringify({
              id: id,
              status: status
          })
      })
        .then((response) => response.json())
        .then((json) => {
              //do something with response 'json'
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.getOrder(id)
        });
      }


      render() {
        Moment.locale('en');
        const { data, isLoading,selectedIndex,isAcceptingOrder } = this.state;
        const { navigation } = this.props;
        const buttons = ['Pending', 'Completed', 'Cancelled']

        const toggleSwitch = (previousState) => {
          this.setState({isAcceptingOrder: previousState});
        }

        const returnStatus = (status) => {
            switch(status) {
                case '1':
                    return (<Text>Pending</Text>);
                    break;
                case '2':
                    return (<Text>Acknowledged</Text>);
                case '3':
                    return (<Text>Complete</Text>);
                case '4':
                    return (<Text>Cancelled</Text>);
                case '5':
                    return (<Text>Expired</Text>);
                case '6':
                    return (<Text>Items Outstanding</Text>);
                case '7':
                  return (<Text>Awaiting Collection</Text>);
                  // code block
                  break;
                default:
                    return (<Text>Status Not Set</Text>);
                  // code block

            }
          }

          const returnPaymentStatus = (status, method) => {
            if (method == 2 || method == 3)
            switch(status) {
                case '1':
                    return (<Text>Manual - Please collect from customer</Text>);
                    break;
                case '2':
                    return (<Text>Manual - Please collect from customer</Text>);
                case '3':
                    return (<Text>Manual - Please collect from customer</Text>);
                case '4':
                    return (<Text>Manual - Please collect from customer</Text>);
                  // code block
                  break;
                default:
                    return (<Text>Status Not Set</Text>);
            }
           if (method == 1) {
                switch(status) {
                    case '1':
                        return (<Text>Pending</Text>);
                        break;
                    case '2':
                        return (<Text>Paid</Text>);
                    case '3':
                        return (<Text>Declined / Cancelled</Text>);

                      // code block
                      break;
                    default:
                        return (<Text>Status Not Set</Text>);

                }


                  // code block

            }
          }


        return (
        <Container>
            <Content>
            <Card transparent>
              <CardItem>
              <Left>
                <Thumbnail source={{uri: this.props.restaurant.image}} />
                  <Body>
                      <Text> {this.props.restaurant.name}</Text>
                      <Text note>Order Console: View Order {this.state.navProps.order.id} </Text>
                  </Body>
              </Left>
              <Right>


              </Right>
              </CardItem>
            </Card>


          {(() => {

          if (isLoading) {
              return  (
                    <View style={{justifyContent:'center',flexDirection: 'column', flex:1, textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                  );
            } else {
              return  (
                <View style={{flex: 1, flexDirection: 'row', justifyContent:'center'}}>

                    <View style={{ flex:4,  justifyContent:'center',padding:20}}>

                    {data.type == '1'  &&
                    <H2 style={{padding:5}}>Table {data.tabledetails.number}</H2>
                    }
                    {data.type == '2'  &&
                    <H2 style={{padding:5}}>Collection Order</H2>
                    }
                    <Text style={{padding:5, marginTop:10}}>Status: {returnStatus(data.status)}</Text>


                    <List>
                            {data.orderitems.map((item, index) =>
                                <ListItem >
                                    <Body>
                                    <Text>{item.quantity} X {item.itemname}</Text>
                                    <Text note numberOfLines={1}>Price: £{item.price}</Text>
                                    {item.parentname && <Text note numberOfLines={1}>Option on: {item.parentname}</Text>}
                                    </Body>
                                    <Right>
                                        <View style={{flex:1}}>
                                            <Text>QTY {item.quantity} {item.delivered}</Text>
                                        </View>
                                        {item.delivered &&
                                          <View style={{flex:1, textAlign:'center'}}>
                                          <Icon style={{ fontSize:17, color: 'green'}} type="FontAwesome5" name='check-circle'  />

                                          </View>
                                        }

                                    </Right>
                                </ListItem >
                            )}
                    </List>
                    <Text style={{marginTop:20}}>Total Price: {data.total_price}</Text>

                    <Text style={{marginTop:20}}>Customer Notes</Text>

                    <Item disabled>

                        <Textarea style={{width:'100%',marginTop:20}} disabled rowSpan={5} bordered placeholder={data.customer_notes} />
          </Item>




                    </View>
                    <View style={{ flex:2, paddingLeft:20}}>
                            <Text>Time Since Order: </Text><TimeAgo time={data.created_at}  interval={1000} />


                            <Text style={{marginTop:30}}>Payment Method: </Text>

                            {data.payment_method == 1 &&
                            <Text style={{marginTop:10}}>Pay By App</Text>
                            }

                            {data.payment_method == 2 &&
                              <Text style={{marginTop:10}}>Card</Text>
                            }

                            {data.payment_method == 3 &&
                              <Text style={{marginTop:10}}>Cash</Text>
                            }

                          <Text style={{marginTop:30}}>Payment Status: </Text>
                          <Text style={{padding:5, marginTop:10}}>{returnPaymentStatus(data.payment_status,data.payment_method)}</Text>


                          <Text style={{marginTop:30}}>Customer : </Text>
                            <Text style={{marginTop:10}}> {data.customer_name} </Text>
                            <Text style={{marginTop:10}}> {data.customer_phone} </Text>

                            {(() => {

                                       if (data.status == '1' || data.status == '6' || data.status == '7') {
                                        return (
                                          <View>
                                          {data.type == '1' &&
                                          <View>

                                            {(data.has_food > 0 && data.has_drink > 0 && data.status == '1') &&
                                                <Button warning style={{marginTop:20, marginRight:5}}  onPress={() => this.updateOrder(data.id,'drinkscomplete')}><Text> Drinks Delivered To Table </Text></Button>
                                            }
                                            <Button success style={{marginTop:30, marginRight:5}}  onPress={() => this.updateOrder(data.id,'complete')}><Text> Delivered To Table </Text></Button>
                                            <Button danger style={{marginTop:30, marginRight:5}}  onPress={() => this.updateOrder(data.id,'cancelled')}><Text> Cancel </Text></Button>
                                          </View>
                                          }
                                          {data.type == '2'  &&
                                          <View>

                                            {(data.status == '1') &&
                                              <View>
                                                <Button warning style={{marginTop:20, marginRight:5}}  onPress={() => this.updateOrder(data.id,'readyforcollection')}><Text> Ready For Collection </Text></Button>
                                                <Text>This will notify the customer it is ready to collect.</Text>
                                              </View>
                                            }
                                           {(data.status == '7') &&
                                                <Button warning style={{marginTop:20, marginRight:5}}  onPress={() => this.updateOrder(data.id,'complete')}><Text> Collected </Text></Button>
                                            }

                                            <Button danger style={{marginTop:30, marginRight:5}}  onPress={() => this.updateOrder(data.id,'cancelled')}><Text> Cancel </Text></Button>
                                          </View>
                                          }


                                      </View>
                                      );
                                      }
                                 })()}


                    </View>


                </View>

                    )
                    }
            })()}

              </Content>
              <FooterBar />
            </Container>
        );
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant,
      }
  }

    const mapDispatchToProps = (dispatch) => {
      return {
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
      }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(BarOrders)
