


const initState = {
    table: [],
    items: [],
    restaurant:[],
    drinksmenu:[],
    foodmenu:[],
    total: 0,
    qty: 0,
    cid:0,
    bartoken:'',
    orderType:''

}

const cartItems= (state = initState,action)=>{

    if(action.type === 'SET_BAR_TOKEN'){
        state.bartoken = action.payload;

        return{
            ...state, bartoken: action.payload
        }
    }

    
    if(action.type === 'SET_ORDER_TYPE'){
        state.orderType = action.payload;

        return{
            ...state, orderType: action.payload
        }
    }

    if(action.type === 'SET_CID_TOKEN'){
        state.cid = action.payload;

        return{
            ...state, cid: action.payload
        }
    }

    if(action.type === 'SET_DRINKS_MENU'){
        state.drinksmenu = action.payload;

        return{
            ...state, drinksmenu: action.payload
        }
    }
    if(action.type === 'SET_FOOD_MENU'){
        state.foodmenu = action.payload;

        return{
            ...state, foodmenu: action.payload
        }
    }

    if(action.type === 'SET_CUSTOMER'){
        state.cid = action.payload;

        return{
            ...state, cid: action.payload
        }
    }

    if(action.type === 'EMPTY_CART'){
        state.items = [];
        state.qty = 0;

        return{
            ...state, items: []
        }
    }


    if(action.type === 'RESET_TABLE'){
        state.table = '';

        return{
            ...state, table: ''
        }
    }


    if(action.type === 'SELECT_RESTAURANT'){
        state.restaurant = action.payload;

        return{
            ...state, restaurant: action.payload
        }
    }

    if(action.type === 'SELECT_TABLE'){
        state.table = action.payload;

        return{
            ...state, table: action.payload
        }
    }

       if(action.type === 'ADD_TO_CART'){
         let existed_item= state.items.find(cartItem => cartItem.id === action.payload.id && cartItem.type === 'drink')
         state.qty += 1

         if(existed_item)
         {
            existed_item.quantity += 1
            existed_item.type = 'drink'
             return{
                ...state,
                 total: state.total + existed_item.price
                  }
        }
         else{
            let addedItem = action.payload
            addedItem.quantity = 1;
            addedItem.type = 'drink';
            //calculating the total
            let newTotal = state.total + addedItem.price

            return{
                ...state,
                items: [...state.items, addedItem],
                total : newTotal
            }

        }
    }

    if(action.type === 'ADD_FOOD_TO_CART'){
        let existed_item= state.items.find(cartItem => cartItem.id === action.payload.id  && cartItem.type === 'food')
        state.qty += 1

        if(existed_item)
        {
           existed_item.quantity += 1
           existed_item.type = 'food'
            return{
               ...state,
                total: state.total + existed_item.price
                 }
       }
        else{
           let addedItem = action.payload
           addedItem.quantity = 1;
           addedItem.type = 'food';
           //calculating the total
           let newTotal = state.total + addedItem.price

           return{
               ...state,
               items: [...state.items, addedItem],
               total : newTotal
           }

       }
   }


    if(action.type === 'REMOVE_ITEM'){
        let itemToRemove= state.items.find(item=> action.payload.id === item.id)
        let new_items = state.items.filter(item=> action.payload.id !== item.id)



        //calculating the total
        let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
        console.log(itemToRemove)
        return{
            ...state,
            items: new_items,
            total: newTotal
        }
    }

    if(action.type=== 'SUB_DRINK_QUANTITY'){
        let selectedItem = state.items.find(item=> item.id === action.payload.id  && item.type === 'drink')
        //if the qt == 0 then it should be removed
        if (selectedItem) {
            state.qty -= 1
            if(selectedItem.quantity === 1){
                let new_items = []
                for (const item of state.items) {
                  if (item.id === action.payload.id  && item.type === 'drink') {
                      // do nothing
                  } else {
                    new_items.push(item)
                  }
                }
                let newTotal = state.total - selectedItem.price
                return{
                    ...state,
                    items: new_items,
                    total: newTotal
                }
            }
            else {

                selectedItem.quantity -= 1
                let newTotal = state.total - selectedItem.price
                return{
                    ...state,
                    total: newTotal
                }
            }
        }

    }

    if(action.type=== 'SUB_FOOD_QUANTITY'){
        let selectedItem = state.items.find(item=> item.id === action.payload.id  && item.type === 'food')
        //if the qt == 0 then it should be removed
        if (selectedItem) {
            state.qty -= 1
            if(selectedItem.quantity === 1){
                let new_items = []

                for (const item of state.items) {
                  if (item.id === action.payload.id  && item.type === 'food') {
                      // do nothing
                  } else {
                    new_items.push(item)
                  }
                }



                let newTotal = state.total - selectedItem.price
                return{
                    ...state,
                    items: new_items,
                    total: newTotal
                }
            }
            else {

                selectedItem.quantity -= 1
                let newTotal = state.total - selectedItem.price
                return{
                    ...state,
                    total: newTotal
                }
            }
        }

    }


    return state
}

export default cartItems



/*
const cartItems = (state = [], action) => {

    switch (action.type) {


        case 'ADD_TO_CART':
            return [...state, action.payload]

        case 'REMOVE_FROM_CART':

            return state.filter(cartItem => cartItem.id !== action.payload.id)

    }



    return state

}





export default cartItems


*/
