import React, { Component } from 'react';
import {  View, TouchableOpacity,AsyncStorage, Image, Dimensions, Platform } from 'react-native';
import { Container, Content,  Text,  Spinner,  } from 'native-base';
import { withNavigation } from 'react-navigation'
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';

import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';



import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
  } from "react-native-chart-kit";

class BarDashboard extends Component {
  static navigationOptions = {
        headerShown: false,
      };
  constructor(props) {
        super(props);
        this.state = {
          data: [],
          stats: {
            today: '',
            "averages": [
              {
                  "minutesComplete__avg": null
              },
              {
                  "minutesComplete__avg": null
              }
          ],
          "dates": ['Loading Chart Data'],
           "stats": [
             0
           ],
          "statsvalues": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ]
        },
          currentOrientation: '',
          orientation: '',
          restaurant:{currency:''},
          isLoading: true,
          loadingStats: true,
        };
      }

  readData = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            // Fetch the bartoken to see if there is an authenticated bar.
            if (tokenName === 'bartoken') { this.props.setBarToken(tokenValue);  }
            // Fetch the cid, the phone identifier/customer identifier for all users.
            if (tokenName === 'cid') {  this.props.setCID(tokenValue); this.setState({cid: tokenValue})  }
          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      getOrientation = () =>
      {
            if( Dimensions.get('screen').width < Dimensions.get('screen').height )
            {
              this.setState({ screenOrientation: 'portrait' });
            }
            else
            {
              this.setState({ screenOrientation: 'landscape' });
            }

      }

  fetchStats() {
        this.setState({ loadingStats: true });
        fetch('https://tabledrop.app/api/stats/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.bartoken
            }
        })
          .then((response) => response.json())
          .then((json) => {
                this.setState({stats: json})

                this.setState({ loadingStats: false });

        })
   }


   closeDrawer = () => {
    this.drawer && this.drawer._root && this.drawer._root.close();
  };
  openDrawer = () => {
    this.drawer && this.drawer._root && this.drawer._root.open();
  };


    async componentDidMount() {


        this.readData("cid");
        this.readData("bartoken");

        await Font.loadAsync({
          Roboto: require('native-base/Fonts/Roboto.ttf'),
          Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
          ...Ionicons.font,
        });
        if (this.props.bartoken) {
          this.setState({ isLoading: false });
        }

        this.getOrientation();
        this.changeListener = Dimensions.addEventListener( 'change', () =>
        {
          this.getOrientation();
        });

        this.focusListener = this.props.navigation.addListener("didFocus", () => {
            this.fetchStats()

       });


        fetch('https://tabledrop.app/api/managerestaurant/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.bartoken
            }
        })
          .then((response) => response.json())
          .then((json) => {
            this.props.selectBar(json[0]);
            this.setState({ restaurant: json[0] });

            this.fetchStats()
            this.setState({ isLoading: false });

          })

  }

  componentWillUnmount() {
    //this.changeListener.remove();

  }

  changePage = (page) => {
    //alert(page)
}


      render() {
        const { isLoading,stats,loadingStats,restaurant } = this.state;
        const { data, userLocation,barcount } = this.state;
        if (this.state.screenOrientation == 'portrait') {
          return (
            <Container>
              <Content>
                 <View style={{padding:20, flex:1, textAlign: 'center', alignItems:'center', backgroundColor:'white'}}>
                      <Image source={require('../assets/rotate.png')} style={{width:150, height:150 }}/>
                      <Text>Please rotate your device to view the Venue Management Console</Text>
                  </View>
              </Content>
              </Container>
          )


        }

        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
              
          <Container>
            <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
            <Content>
            <View>


          {(() => {

          if (isLoading) {
              return  (
                    <View style={{justifyContent:'center',flexDirection: 'column', flex:1, textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                  );
            } else {


              return  (
                <View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                          <TouchableOpacity onPress={() => this.props.navigation.navigate('BarOrders',{'filter':'pending'})} style={{ flex:1, height: 150,textAlign:'center', justifyContent:'center',backgroundColor:'#edb368',margin:5,borderRadius:35}}>
                                <Text style={{textAlign:'center'}}>Pending Orders</Text>
                                {loadingStats ? (
                                     <Spinner color='blue'/>
                                      ) : (
                                        <Text style={{textAlign:'center',fontSize:40,color:'#a57335', marginTop:5}}>{stats.pending_orders}</Text>
                                        )}
                            </TouchableOpacity>
                            <TouchableOpacity  onPress={() => this.props.navigation.navigate('BarOrders',{'filter':'today'})}   style={{ flex:1, height: 150,textAlign:'center',justifyContent:'center',backgroundColor:'#94a9ce', margin:5,borderRadius:35}}>
                                <Text style={{textAlign:'center'}}>Orders Today</Text>
                                  {loadingStats ? (
                                     <Spinner color='blue'/>
                                      ) : (
                                    <Text style={{textAlign:'center',fontSize:40,color:'#476DB6', marginTop:5}}>{stats.today_orders}</Text>
                                      )}
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => this.props.navigation.navigate('BarOrders',{'filter':'cancelled'})} style={{ flex:1, height: 150,textAlign:'center', justifyContent:'center',backgroundColor:'#94a9ce',margin:5,borderRadius:35}}>
                                <Text style={{textAlign:'center'}}>Cancelled Orders</Text>
                                {loadingStats ? (
                                     <Spinner color='blue'/>
                                      ) : (
                                        <Text style={{textAlign:'center',fontSize:40,color:'#476DB6', marginTop:5}}>{stats.cancelled_orders}</Text>
                                        )}

                            </TouchableOpacity>


                    </View>
                    <View style={{flexDirection:'row',marginTop:15}}>
                        <View style={{flex:2,marginLeft:10}}>
                        <Text style={{textAlign:'center'}}>Avg. Delivery Time Today</Text>
                        {loadingStats ? (
                                     <Spinner color='blue'/>
                                      ) : (
                                        <View>
                                        {stats.averages[0].minutesComplete__avg ? (
                                          <Text style={{textAlign:'center',fontSize:30,color:'#476DB6'}}>{stats.averages[0].minutesComplete__avg.toFixed(0)} m</Text>

                                        ) : (
                                          <Text style={{textAlign:'center',fontSize:30,color:'#476DB6'}}>No Data</Text>
                                        )}
                                        </View>
                                        )}
                        </View>
                        <View style={{flex:2}}>
                                <Text style={{textAlign:'center'}}>Avg. Delivery Time Overall</Text>
                                {loadingStats ? (
                                     <Spinner color='blue'/>
                                      ) : (
                                        <View>
                                          {stats.averages[1].minutesComplete__avg ? (
                                            <Text style={{textAlign:'center',fontSize:30,color:'#476DB6'}}>{stats.averages[1].minutesComplete__avg.toFixed(0)} m</Text>
                                          ) : (
                                            <Text style={{textAlign:'center',fontSize:30,color:'#476DB6'}}>No Data</Text>
                                          )}
                                        </View>
                                        )}
                        </View>

                    </View>
                    <View style={{flexDirection:'row',marginTop:15}}>

                    <View style={{marginTop:40, marginLeft:10,flex:2}}>
                        <Text style={{textAlign:'center'}}>Last 7 Days Order Count</Text>
                          {loadingStats ? (
                             <Spinner color='blue'/>
                              ) : (

                              <LineChart
                            data={{
                                labels: stats.dates,
                                datasets: [
                                {
                                    data: stats.stats
                                },
                                ],
                            }}
                            width={(Dimensions.get('window').width - 16) /2}
                            height={220}
                            chartConfig={{
                                backgroundColor: '#1cc910',
                                backgroundGradientFrom: '#eff3ff',
                                backgroundGradientTo: '#efefef',
                                decimalPlaces: 0,
                                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                                style: {
                                borderRadius: 16,
                                },
                            }}
                            style={{
                                marginVertical: 8,
                                borderRadius: 16,
                            }}
                        />
                      )}
                    </View>
                    <View style={{marginTop:40, marginLeft:10,flex:2}}>
                    <Text style={{textAlign:'center'}}>Last 7 Days Order Value</Text>
                          {loadingStats ? (
                             <Spinner color='blue'/>
                              ) : (

                              <BarChart
                            data={{
                                labels: stats.dates,
                                datasets: [
                                {
                                    data: stats.statsvalues
                                },
                                ],
                            }}
                            width={(Dimensions.get('window').width - 16) /2}
                            height={220}
                            chartConfig={{
                                backgroundColor: '#1cc910',
                                backgroundGradientFrom: '#b5c0e0',
                                backgroundGradientTo: '#cbd3f2',
                                decimalPlaces: 0,
                                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                                style: {
                                borderRadius: 16,
                                },
                            }}
                            style={{
                                marginVertical: 8,
                                borderRadius: 16,
                            }}
                        />
                      )}
                    </View>


                    </View>

                </View>
                    )}
            })()}

        </View>
        </Content>
       <FooterBar />
       </Container>
       </Drawer>
        );
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant,
      }
  }

    const mapDispatchToProps = (dispatch) => {
      return {
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
          setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
          setCID: (token) => dispatch({ type: 'SET_CID_TOKEN', payload: token })
      }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(BarDashboard))
