import React from 'react';
import { Container, Header, Footer, FooterTab, Icon, Button, Text } from 'native-base';
import { withNavigation } from 'react-navigation'
import {  View, Image } from 'react-native';
import {connect} from 'react-redux'

const FooterBar = (props) => (
  <Footer>

  {(() => {
    if ( props.bartoken ) {
        return (
          <FooterTab>
            <Button vertical onPress={() => props.navigation.navigate('BarDashboard')}>
              <Icon name="chart-bar" type="FontAwesome5"/>
              <Text>Dashboard</Text>
            </Button>

            <Button vertical onPress={() => props.navigation.navigate('BarOrders',{'filter':'pending'})}>
              <Icon name="cart" />
              <Text>Order Console</Text>
            </Button>

            <Button vertical onPress={() => props.navigation.navigate('BarSettings')}>
              <Icon name="settings" />
              <Text>Settings</Text>
            </Button>

          </FooterTab>
          )
        } else {
        return(
          <FooterTab>
            <Button vertical onPress={() => props.navigation.navigate('BarList')}>
              <Icon name="home" type="FontAwesome5" />
              <Text>Find Venues</Text>
            </Button>
            <Button vertical onPress={() => props.navigation.navigate('myOrders')}>
              <Icon name="cart" />
              <Text>My Orders</Text>
            </Button>
          </FooterTab>

        )
        }
})()}
</Footer>


);
const mapStateToProps = (state) => {
  return {
      bartoken: state.bartoken
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(FooterBar))
