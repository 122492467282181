import React, { Component } from 'react';
import {  View,StyleSheet, TouchableOpacity, Dimensions, Platform, Alert,ScrollView,Modal,TouchableHighlight,AsyncStorage } from 'react-native';
import { Container, Spinner,  Left, Text, Body, Right, Button,  Icon, List, Card, CardItem, ListItem,  Tab, Tabs, ScrollableTab, Footer } from 'native-base';
import CartIcon from './CartIcon.js'
import { bindActionCreators } from "redux"
import {connect} from 'react-redux'
import NumberFormat from 'react-number-format';
import { withNavigation } from 'react-navigation'
import Import from '../thirdparty/expo-stripe-checkout-sca/import';
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'

import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';


class BarStock extends Component {
  static navigationOptions = {
    headerShown: false,
  };
    constructor(props) {
        super(props);
        this.state = {
          selectedBar: props.navigation.state.params,
          drinkData: props.drinksmenu,
          foodData: props.foodmenu,
          isLoading: true,
          selectedIndex: 0,
          optionsLoading:false,
          drinksmodalVisible:false,
          drinkOptions:[],
          foodOptions:[],
          scrollOffset: null,          
          foodmodalVisible:false,
          restaurant:{},
          currentTab:0,
          restaurant:'',
          activeMenu:'drink'
        };
        this.scrollViewRef = React.createRef();
      }

      readData = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            if (tokenName === 'bartoken') { this.props.setBarToken(tokenValue);  }
          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
      changePage = (page) => {
        //alert(page)
    }


      componentDidMount() {

      //  alert(this.state.selectedBar.type) - collection or tableservice
        this.readData("bartoken");

            fetch('https://tabledrop.app/api/managerestaurant/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.props.bartoken
                }
            })
            .then((response) => response.json())
            .then((json) => {
                this.setState({restaurant: json[0]})
                this.props.selectBar(json[0]);
                this.loadMenu()
            })
      }



      loadMenu() { 
        fetch('https://tabledrop.app/api/drinkcategory/?restaurant=' + this.state.restaurant.id)
        .then((response) => response.json())
        .then((json) => {
          this.props.setDrinksMenu(json)
          this.setState({drinkData:json})
      })
        .catch((error) => console.error(error))
        .finally(() => {
         this.setState({ isLoading: false });
        });

        fetch('https://tabledrop.app/api/foodcategory/?restaurant=' + this.state.restaurant.id)
        .then((response) => response.json())
        .then((json) => {
          this.props.setFoodMenu(json)
          this.setState({foodData:json})
      })
        .catch((error) => console.error(error))
        .finally(() => {
        })

      }


      loadOptions(id,type='DRINK') {

        if (type == 'DRINK') {
          this.setState({drinksmodalVisible: true});
          this.setState({optionsLoading: true});
          this.setState({drinkOptions:[]})

          fetch('https://tabledrop.app/api/drinkoptions/?id=' + id)
          .then((response) => response.json())
          .then((json) => {
            this.setState({drinkOptions:json})
            this.setState({foodOptions:[]})
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({optionsLoading: false});

          // this.setState({ isLoading: false });
          });
        } else {

          this.setState({foodmodalVisible: true});
          this.setState({optionsLoading: true});
          this.setState({foodOptions:[]})

          fetch('https://tabledrop.app/api/foodoptions/?id=' + id)
          .then((response) => response.json())
          .then((json) => {
            this.setState({foodOptions:json})
            this.setState({drinkOptions:[]})
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({optionsLoading: false});
          // this.setState({ isLoading: false });
          });
        }

      }



      updateAvailable = (item,type,available) => {
        this.setState({isLoading: true});
        fetch('https://tabledrop.app/api/updateproduct/' + item.id + '/', {
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Token ' + this.props.bartoken
          },
          body: JSON.stringify({
              id: item.id,
              type: type,
              available: available
          })
      })
        .then((response) => response.json())
        .then((json) => {
              //do something with response 'json'
        })
        .catch((error) => console.error(error))
        .finally(() => {
            //this.setState({isLoading: false});
            this.loadMenu()
            //this.setState({isLoading: false});
         });
         //this.setState({isLoading: false});
    
      }





      handleOnScroll = event => {
        this.setState({
          scrollOffset: event.nativeEvent.contentOffset.y,
        });
      };
      handleScrollTo = p => {
        if (this.scrollViewRef.current) {
          this.scrollViewRef.current.scrollTo(p);
        }
      };


      render() {
        const { drinkData, foodData, isLoading, selectedIndex,activeMenu,drinksmodalVisible,drinkOptions,foodOptions,foodmodalVisible,optionsLoading } = this.state;
        const buttons = ['Drinks', 'Food']

        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue' />
                </Container>
              );
        } else {
        return (
            <Drawer
            ref={(ref) => { this.drawer = ref; }}
            content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
            onClose={() => this.closeDrawer()} >
                          
          <Container>
          <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>

           
             <Import.Modal  animationType="slide" transparent={true}  visible={drinksmodalVisible}
                     scrollTo={this.handleScrollTo}
                     scrollOffset={this.state.scrollOffset}
                     scrollOffsetMax={400 - 300}
               >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                  <View style={styles.scrollableModal}>
          <ScrollView
            ref={this.scrollViewRef}
            onScroll={this.handleOnScroll}
            scrollEventThrottle={16}>


                     {optionsLoading && <Spinner color='blue' />}
                      <List>
                      {drinkOptions.map((item, index) =>
                                      <ListItem>
                                          <Body>
                                            <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                            <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>
                                          </Body>
                                          <Right>
                                          <View style={{flex: 1, flexDirection: 'row'}}>

                     
                                          </View>
                                          </Right>
                                          </ListItem>
                                      )}
                                                                  </List>
                                                                  </ScrollView>
        </View>
                    <Button 
                      style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
                      onPress={() => {
                        this.setState({drinksmodalVisible:false}); 
                      }}
                    >
                      <Text style={styles.textStyle}>Close</Text>
                    </Button>
                  </View>
                </View>
      </Import.Modal>


      <Import.Modal  animationType="slide" transparent={true}  visible={foodmodalVisible} 
                           scrollTo={this.handleScrollTo}
                           scrollOffset={this.state.scrollOffset}
                           scrollOffsetMax={400 - 300} >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                  <View style={styles.scrollableModal}>
          <ScrollView
            ref={this.scrollViewRef}
            onScroll={this.handleOnScroll}
            scrollEventThrottle={16}>
              {optionsLoading && <Spinner color='blue' />}
              <List>
              {foodOptions.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>
                      

                                  </View>
                                  </Right>
                              </ListItem >
                              )}
              </List>
              </ScrollView>
              </View>
         
            <Button 
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={() => {
                this.setState({foodmodalVisible:false}); 
              }}
            >
              <Text style={styles.textStyle}>Close</Text>
            </Button>

          </View>
        </View>
    
      </Import.Modal>



                <View style={{flexDirection:'row'}}>
                    <TouchableOpacity onPress={() => this.setState({activeMenu:'drink'})}    style={activeMenu == 'drink' ? styles.activeTabStyle : styles.inactiveTabStyle} >
                      <View  style={{flexDirection: "row",textAlign:'center',color:'white', justifyContent:'center'}}>
                         <Icon style={{ color:'#fff',fontSize:19 }} type="FontAwesome5" name='glass-martini'  />
                         <Text style={{paddingLeft:5, color:'white'}}>Manage Drink</Text>
                      </View>
                    </TouchableOpacity> 
                    <TouchableOpacity  onPress={() => this.setState({activeMenu:'food'})}  style={activeMenu == 'food' ? styles.activeTabStyle : styles.inactiveTabStyle}>
                      <View  style={{flexDirection: "row",textAlign:'center',color:'white', justifyContent:'center'}}>
                          <Icon style={{ color:'#fff',fontSize:19 }} type="FontAwesome5" name='utensils'  />
                          <Text style={{paddingLeft:5, color:'white'}}>Manage Food</Text>

                      </View>
                      </TouchableOpacity>
                </View>

              {(() => {
              if (activeMenu == 'drink') {
              return (

              <Tabs style={{width:'100%'}} renderTabBar={()=> <ScrollableTab />}>
              {drinkData.map((menucat, index) =>
                    <Tab heading={menucat.name}>
                      <ScrollView>
                      <List>
                            {menucat.drinks.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    {! item.isparent && <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>}
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>


                                  {item.available && <Button success onPress={() => this.updateAvailable(item,'drink',false)}><Text>Available</Text></Button>}
                                  {! item.available && <Button danger onPress={() => this.updateAvailable(item,'drink',true)}><Text>Unavailable</Text></Button>}
              



                                  </View>
                                  </Right>
                              </ListItem >
                              )}

                        {(() => {
                                if (menucat.drinks.length == 0) {
                                      return (<Text  style={{justifyContent:'center', textAlign: 'center', padding:40}}>No items in category {menucat.name}</Text>)
                                }

                          })()}

                    </List>
    

                    </ScrollView>
                    </Tab>
              )}


            </Tabs>
            )
              }
              })()}



            {(() => {
              if (activeMenu == 'food') {
              return (

               <Tabs style={{width:'100%'}} renderTabBar={()=> <ScrollableTab />}>
              {foodData.map((menucat, index) =>
                    <Tab heading={menucat.name}>
                      <ScrollView>
                      <List>
                            {menucat.foods.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    {! item.isparent &&  <Text note numberOfLines={1}>Price: £{item.price}</Text>}
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>

                                  {item.available && <Button success onPress={() => this.updateAvailable(item,'food',false)}><Text>Available</Text></Button>}
                                  {! item.available && <Button danger onPress={() => this.updateAvailable(item,'food',true)}><Text>Unavailable</Text></Button>}


                                  </View>
                                  </Right>
                              </ListItem >
                              )}

                        {(() => {
                                if (menucat.foods.length == 0) {
                                      return (<Text  style={{justifyContent:'center', textAlign: 'center', padding:40}}>No items in category {menucat.name}</Text>)
                                }

                          })()}

                    </List>
                    </ScrollView>
                    </Tab>
              )}


            </Tabs>

              )
              }
              })()}

            <FooterBar />
            </Container>
              </Drawer>

            );
      }
      }
    };

    var styles = StyleSheet.create({
      activeTabStyle: {
          backgroundColor:'#3F51B5',
          flex:1,
          height: 50,
          textAlign:'center',
          color:'white',
          justifyContent:'center',

      },
      container: {
        display: Platform.OS === 'web' ? 'block' : 'flex'
      },
      inactiveTabStyle: {
        backgroundColor:'#818cc4',
        flex:1,
        height: 50,
        textAlign:'center',
        color:'white',
        justifyContent:'center',
      },
      buttonGo: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize:18,
        borderRadius:10
  
    },centeredView: {
      justifyContent: "center",
      alignItems: "center",
      flex:1,
      backgroundColor: Platform.OS === 'web' ? '' : 'rgba(0, 0, 0, 0.5)'
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 2,
      paddingBottom:15,
      justifyContent:'center',
      minWidth:Dimensions.get('window').width - 20,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.75,
      shadowRadius: 3.84,
      elevation: 5
    },
    openButton: {
      backgroundColor: "#F194FF",
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    scrollableModal: {
      height: 300,
    },
    scrollableModalContent1: {
      height: 200,
      backgroundColor: '#87BBE0',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollableModalText1: {
      fontSize: 20,
      color: 'white',
    },
    scrollableModalContent2: {
      height: 200,
      backgroundColor: '#A9DCD3',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollableModalText2: {
      fontSize: 20,
      color: 'white',
    },

    });


  const mapStateToProps = (state) => {
    return {
      table: state.table,
      cid: state.cid,
      drinksmenu: state.drinksmenu,
      foodmenu: state.foodmenu,
      bartoken: state.bartoken,
      restaurant: state.restaurant,
    }
}

  const mapDispatchToProps = (dispatch) => {
    return {
        selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
        setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
        setDrinksMenu: (drinks) => dispatch({ type: 'SET_DRINKS_MENU', payload: drinks }),
        setFoodMenu: (foods) => dispatch({ type: 'SET_FOOD_MENU', payload: foods }),        
        setCID: (token) => dispatch({ type: 'SET_CID_TOKEN', payload: token })
    }
}


    export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(BarStock))
