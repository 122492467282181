import React, { Component } from 'react';
import { AsyncStorage  } from 'react-native';
import { Container,  Button, Form, Content,Text,Icon, Item, Input,  Spinner } from 'native-base';
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'

import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';


class BarLogin extends Component {
    static navigationOptions = {
        headerShown: false,
    };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          cid:'',
          username:'',
          password:'',
          table:[],
          isLoading: true,
          loggedIn:false,
          bartoken:''
        };
      }


      async getToken(tokenName) {
        try {
          let userData = await AsyncStorage.getItem(tokenName);
          let data = userData;

          if (data) {
              this.props.setBarToken(data);
              this.props.navigation.navigate('BarOrders')
          }
          this.setState({isLoading: false});

          console.log(data);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }

      componentDidMount() {
          //check if there is 'bartoken' already, if so, redirect.
          if (this.props.bartoken) {
            this.props.navigation.navigate('BarOrders')
          }
          this.getToken("bartoken")
      }

      async storeToken(tokenName, tokenValue) {
        try {
           await AsyncStorage.setItem(tokenName, tokenValue);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }


      loginUser() {
        this.setState({isLoading: true});
        fetch('https://tabledrop.app/api/auth/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.state.username.toLowerCase().trim(),
                password: this.state.password
            })
        })  .then((response) => response.json())
            .then((responseData) => {
                console.log("RESULTS HERE:", responseData)
                        this.setState({
                         isLoading: false
                    }, function(){
                        if (responseData.token) {
                            this.storeToken("bartoken",responseData.token.toString());
                            this.props.setBarToken(responseData.token);
                            this.setState({loggedIn: true});

                            fetch('https://tabledrop.app/api/managerestaurant/', {
                              method: 'GET',
                              headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json',
                                  'Authorization': 'Token ' + responseData.token
                              }
                          })
                            .then((response) => response.json())
                            .then((json) => {
                              this.props.selectBar(json[0]);
                              this.setState({ isLoading: false });
                            })



                            this.props.navigation.navigate('BarDashboard', {phone: this.state.phone})
                        } else {
                            alert('Login Failed');
                        }
                    });
            })
            .catch((error) =>{
                console.error(error);
                alert(error);
                this.setState({isLoading: false});
            })
      }

      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
      changePage = (page) => {
        //alert(page)
    }


      render() {
        const { data, isLoading,loggedIn } = this.state;

        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue'/>
                </Container>
              );
        } else {

        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
    
          <Container>
            <HeaderBar  openDrawer={this.openDrawer.bind(this)} />
            <Content>
                    <Text style={{textAlign:'center',padding:10}}>Venue Login</Text>
                    <Form>
                        <Item floatingLabel>
                            <Icon active name='person' />
                            <Input  onChangeText={(username) => this.setState({ username: username })} placeholder='Email Address'/>
                        </Item>
                        <Item floatingLabel>
                            <Icon active name='lock' />
                            <Input secureTextEntry={true}  onChangeText={(password) => this.setState({ password: password })} placeholder='Password'/>
                        </Item>
                        <Button  primary style={{marginTop:20,borderRadius:10}} onPress={() => this.loginUser()}><Text> Login </Text></Button>
                          <Text note style={{padding:30}}>v1.0.10.14F</Text>
                    </Form>
                </Content>
            <FooterBar />
        </Container>
        </Drawer>
        );
     } // is loading
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
          selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table }),
          setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),

      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(BarLogin)
