import React, { Component } from 'react';
import {  Text, TouchableOpacity } from 'react-native';
import {  Icon } from 'native-base';
import {Provider} from 'react-redux'
import store from '../store'
import { withNavigation } from 'react-navigation'
import { connect } from 'react-redux'
import {  Badge } from 'react-native-elements'


const CartIcon = (props) => (
        <Provider store={store}>
        <TouchableOpacity onPress={() => props.navigation.navigate('cartScreen')} style={{right:20}} >
        <Text> 
     <Icon onPress={() => props.navigation.navigate('cartScreen')} name="ios-cart" style={{fontSize:40}}  size={50} /> </Text>
       <Badge
                status="success"
                value={props.qty} 
                containerStyle={{ position: 'absolute', top: -4, right: 0 }} />
       </TouchableOpacity >
      </Provider>    );




const mapStateToProps = (state) => {
    return {
        cartItems: state.items,
        qty: state.qty
    }
}

export default connect(mapStateToProps)(withNavigation(CartIcon));