import React, { Component } from 'react';
import {  View,StyleSheet, TouchableOpacity, Dimensions, Platform, Alert,ScrollView,Modal,TouchableHighlight } from 'react-native';
import { Container, Spinner,  Left, Text, Body, Right, Button,  Icon, List, Card, CardItem, ListItem,  Tab, Tabs, ScrollableTab, Footer } from 'native-base';
import CartIcon from './CartIcon.js'
import { bindActionCreators } from "redux"
import {connect} from 'react-redux'
import NumberFormat from 'react-number-format';
import { withNavigation } from 'react-navigation'
import Import from '../thirdparty/expo-stripe-checkout-sca/import';


class loadMenu extends Component {
  static navigationOptions = {
    title: 'Choose from menu',
    headerRight:<CartIcon/>
  };
    constructor(props) {
        super(props);
        this.state = {
          selectedBar: props.navigation.state.params,
          drinkData: props.drinksmenu,
          foodData: props.foodmenu,
          isLoading: false,
          selectedIndex: 0,
          optionsLoading:false,
          drinksmodalVisible:false,
          drinkOptions:[],
          foodOptions:[],
          scrollOffset: null,          
          foodmodalVisible:false,
          currentTab:0,
          restaurant:'',
          activeMenu:'drink'
        };
        this.scrollViewRef = React.createRef();
      }


      componentDidMount() {

      //  alert(this.state.selectedBar.type) - collection or tableservice

        if (Platform.OS === 'web') {

          let table = getParameterByName('t');
          let venue = getParameterByName('venue');
          
     
          if (table && venue) {
            this.setState({QRCodeUser:true})
            this.setState({table:table})
            this.setState({venue:venue})
            this.setState({selectedBar: {id: venue, type:'tableservice'}});
            this.props.setOrderType('tableservice');

            this.props.selectBar({id: venue});
            
            
            fetch('https://tabledrop.app/api/restaurantpayment/?id=' + venue )
            .then((response) => response.json())
            .then((json) => {
                this.props.selectBar(json[0]);

            })
            .catch((error) => console.error(error))
            .finally(() => {
              //this.setState({ isLoading: false });
            });

            fetch('https://tabledrop.app/api/table/' + table + '/')
            .then((response) => response.json())
            .then((json) => {
                this.props.selectTable(json)

            })
            .catch((error) => console.error(error))
            .finally(() => {
              //this.setState({ isLoading: false });
            });


            fetch('https://tabledrop.app/api/drinkcategory/?restaurant=' + venue)
            .then((response) => response.json())
            .then((json) => {
              this.props.setDrinksMenu(json)
              this.setState({drinkData:json})

          })
            .catch((error) => console.error(error))
            .finally(() => {
            // this.setState({ isLoading: false });
            });
    
            fetch('https://tabledrop.app/api/foodcategory/?restaurant=' + venue)
            .then((response) => response.json())
            .then((json) => {
              this.props.setFoodMenu(json)
              this.setState({foodData:json})
          })
            .catch((error) => console.error(error))
            .finally(() => {
              this.setState({ isLoading: false });
            })
            
          }
    
          function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        } 
      }



          if (!this.props.drinksmenu) {
              // reload the props
              alert('no drinks data');

          }

      }


      loadOptions(id,type='DRINK') {

        if (type == 'DRINK') {
          this.setState({drinksmodalVisible: true});
          this.setState({optionsLoading: true});
          this.setState({drinkOptions:[]})

          fetch('https://tabledrop.app/api/drinkoptions/?id=' + id)
          .then((response) => response.json())
          .then((json) => {
            this.setState({drinkOptions:json})
            this.setState({foodOptions:[]})
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({optionsLoading: false});

          // this.setState({ isLoading: false });
          });
        } else {


          this.setState({foodmodalVisible: true});
          this.setState({optionsLoading: true});
          this.setState({foodOptions:[]})

          fetch('https://tabledrop.app/api/foodoptions/?id=' + id)
          .then((response) => response.json())
          .then((json) => {
            this.setState({foodOptions:json})
            this.setState({drinkOptions:[]})
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({optionsLoading: false});

          // this.setState({ isLoading: false });
          });


        }

      }

      handleOnScroll = event => {
        this.setState({
          scrollOffset: event.nativeEvent.contentOffset.y,
        });
      };
      handleScrollTo = p => {
        if (this.scrollViewRef.current) {
          this.scrollViewRef.current.scrollTo(p);
        }
      };


      render() {
        const { drinkData, foodData, isLoading, selectedIndex,activeMenu,drinksmodalVisible,drinkOptions,foodOptions,foodmodalVisible,optionsLoading } = this.state;
        const buttons = ['Drinks', 'Food']

        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue' />
                </Container>
              );
        } else {
        return (
             <Container style={styles.container}>
           
             <Import.Modal  animationType="slide" transparent={true}  visible={drinksmodalVisible}
                     scrollTo={this.handleScrollTo}
                     scrollOffset={this.state.scrollOffset}
                     scrollOffsetMax={400 - 300}
               >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                  <View style={styles.scrollableModal}>
          <ScrollView
            ref={this.scrollViewRef}
            onScroll={this.handleOnScroll}
            scrollEventThrottle={16}>


                     {optionsLoading && <Spinner color='blue' />}
                      <List>
                      {drinkOptions.map((item, index) =>
                                      <ListItem>
                                          <Body>
                                            <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                            <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>
                                          </Body>
                                          <Right>
                                          <View style={{flex: 1, flexDirection: 'row'}}>
                                          <Icon style={{ color:'#555555', fontSize: 30 }} type="FontAwesome5" name='minus-square' onPress={() => this.props.removeItemFromCart(item)} />

                                          {(() => {
                                                let cartItem = this.props.cartItems.items.find(cartItem=> cartItem.id === item.id && cartItem.type === 'drink')
                                                if ( cartItem ) {
                                                  return (
                                                    <Text style={{ marginLeft:10, fontSize: 20 , paddingTop:4}}>{cartItem.quantity}</Text>
                                                    )
                                                  } else {
                                                    return(
                                                      <Text style={{ marginLeft:10, fontSize: 20, paddingTop:4}}>0</Text>
                                                    )
                                                  }
                                                
                                          })()}

                                          <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square'  onPress={() => this.props.addItemToCart(item)} />
                                          </View>
                                          </Right>
                                          </ListItem>
                                      )}
                                                                  </List>
                                                                  </ScrollView>
        </View>
                    <Button 
                      style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
                      onPress={() => {
                        this.setState({drinksmodalVisible:false}); 
                      }}
                    >
                      <Text style={styles.textStyle}>Close</Text>
                    </Button>
                  </View>
                </View>
      </Import.Modal>


      <Import.Modal  animationType="slide" transparent={true}  visible={foodmodalVisible} 
                           scrollTo={this.handleScrollTo}
                           scrollOffset={this.state.scrollOffset}
                           scrollOffsetMax={400 - 300} >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                  <View style={styles.scrollableModal}>
          <ScrollView
            ref={this.scrollViewRef}
            onScroll={this.handleOnScroll}
            scrollEventThrottle={16}>
              {optionsLoading && <Spinner color='blue' />}
              <List>
              {foodOptions.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>
                                  <Icon style={{ color:'#555555', fontSize: 30 }} type="FontAwesome5" name='minus-square' onPress={() => this.props.removeFoodItemFromCart(item)} />
                                  {(() => {
                                        let cartItem = this.props.cartItems.items.find(cartItem=> cartItem.id === item.id && cartItem.type === 'food')
                                        if ( cartItem ) {
                                          return (
                                            <Text style={{ marginLeft:10, fontSize: 20 , paddingTop:4}}>{cartItem.quantity}</Text>
                                            )
                                          } else {
                                            return(
                                              <Text style={{ marginLeft:10, fontSize: 20, paddingTop:4}}>0</Text>
                                            )
                                          }                                       
                                  })()}
                                   <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square'  onPress={() => this.props.addFoodItemToCart(item)} />
                                  </View>
                                  </Right>
                              </ListItem >
                              )}
              </List>
              </ScrollView>
              </View>
         
            <Button 
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={() => {
                this.setState({foodmodalVisible:false}); 
              }}
            >
              <Text style={styles.textStyle}>Close</Text>
            </Button>

          </View>
        </View>
    
      </Import.Modal>



                <View style={{flexDirection:'row'}}>
                    <TouchableOpacity onPress={() => this.setState({activeMenu:'drink'})}    style={activeMenu == 'drink' ? styles.activeTabStyle : styles.inactiveTabStyle} >
                      <View  style={{flexDirection: "row",textAlign:'center',color:'white', justifyContent:'center'}}>
                         <Icon style={{ color:'#fff',fontSize:19 }} type="FontAwesome5" name='glass-martini'  />
                         <Text style={{paddingLeft:5, color:'white'}}>Drink</Text>
                      </View>
                    </TouchableOpacity> 
                    <TouchableOpacity  onPress={() => this.setState({activeMenu:'food'})}  style={activeMenu == 'food' ? styles.activeTabStyle : styles.inactiveTabStyle}>
                      <View  style={{flexDirection: "row",textAlign:'center',color:'white', justifyContent:'center'}}>
                          <Icon style={{ color:'#fff',fontSize:19 }} type="FontAwesome5" name='utensils'  />
                          <Text style={{paddingLeft:5, color:'white'}}>Food</Text>

                      </View>
                      </TouchableOpacity>
                </View>

              {(() => {
              if (activeMenu == 'drink') {
              return (

              <Tabs style={{width:'100%'}} renderTabBar={()=> <ScrollableTab />}>
              {drinkData.map((menucat, index) =>
                    <Tab heading={menucat.name}>
                      <ScrollView>
                      <List>
                            {menucat.drinks.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    {! item.isparent && <Text note numberOfLines={1}>{item.size}  | Price: {this.props.restaurant.currency}{item.price}</Text>}
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>
                                  {(! item.isparent && item.available) && <Icon style={{ color:'#555555', fontSize: 30 }} type="FontAwesome5" name='minus-square' onPress={() => this.props.removeItemFromCart(item)} />}

                                  {(() => {
                                        if (item.isparent || ! item.available) {} else {
                                     
                                        let cartItem = this.props.cartItems.items.find(cartItem=> cartItem.id === item.id && cartItem.type === 'drink')
                                        if ( cartItem ) {
                                          return (
                                            <Text style={{ marginLeft:10, fontSize: 20 , paddingTop:4}}>{cartItem.quantity}</Text>
                                            )
                                          } else {
                                            return(
                                              <Text style={{ marginLeft:10, fontSize: 20, paddingTop:4}}>0</Text>
                                            )
                                          }
                                        }
                                  })()}

                                   {(! item.isparent && item.available) && <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square'  onPress={() => this.props.addItemToCart(item)} />}

                                   {(item.isparent > 0 && item.available) && <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square' onPress={() => this.loadOptions(item.id,'DRINK')}/>}

                                  {!item.available && <Text>Unavailable</Text>}


                                  </View>
                                  </Right>
                              </ListItem >
                              )}

                        {(() => {
                                if (menucat.drinks.length == 0) {
                                      return (<Text  style={{justifyContent:'center', textAlign: 'center', padding:40}}>No items in category {menucat.name}</Text>)
                                }

                          })()}

                    </List>
    

                    </ScrollView>
                    </Tab>
              )}


            </Tabs>
            )
              }
              })()}



            {(() => {
              if (activeMenu == 'food') {
              return (

               <Tabs style={{width:'100%'}} renderTabBar={()=> <ScrollableTab />}>
              {foodData.map((menucat, index) =>
                    <Tab heading={menucat.name}>
                      <ScrollView>
                      <List>
                            {menucat.foods.map((item, index) =>
                              <ListItem >
                                  <Body>
                                    <Text style={{fontWeight: "bold"}}>{item.name}</Text>
                                    {! item.isparent &&  <Text note numberOfLines={1}>Price: £{item.price}</Text>}
                                  </Body>
                                  <Right>
                                  <View style={{flex: 1, flexDirection: 'row'}}>
                                  {(! item.isparent && item.available) && <Icon style={{ color:'#555555', fontSize: 30 }} type="FontAwesome5" name='minus-square' onPress={() => this.props.removeFoodItemFromCart(item)} />}

                                  {(() => {
                                        if (item.isparent || ! item.available) {} else {

                                        let cartItem = this.props.cartItems.items.find(cartItem=> cartItem.id === item.id && cartItem.type === 'food')
                                        if ( cartItem ) {
                                          return (
                                            <Text style={{ marginLeft:10, fontSize: 20 , paddingTop:4}}>{cartItem.quantity}</Text>
                                            )
                                          } else {
                                            return(
                                              <Text style={{ marginLeft:10, fontSize: 20, paddingTop:4}}>0</Text>
                                            )
                                          }
                                        }
                                        })()}

                                  {(! item.isparent && item.available) && <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square'  onPress={() => this.props.addFoodItemToCart(item)} />}
                                  {(item.isparent > 0 && item.available) && <Icon style={{ marginLeft:10, color:'#555555', fontSize: 30 }} type="FontAwesome5" name='plus-square' onPress={() => this.loadOptions(item.id,'FOOD')}/>}
                                  {!item.available && <Text>Unavailable</Text>}

                                  </View>
                                  </Right>
                              </ListItem >
                              )}

                        {(() => {
                                if (menucat.foods.length == 0) {
                                      return (<Text  style={{justifyContent:'center', textAlign: 'center', padding:40}}>No items in category {menucat.name}</Text>)
                                }

                          })()}

                    </List>
                    </ScrollView>
                    </Tab>
              )}


            </Tabs>

              )
              }
              })()}






            <Footer>
                            <Left>
                              <NumberFormat
                                renderText={
                                  value => <Text style={{ paddingLeft:20, fontSize: 20 }}>{value}</Text>
                                }
                                value={this.props.total.toFixed(2)}
                                displayType={'text'}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'£'} /></Left>
                            <Left>
                            {(() => {
                                if (this.props.total > 0) {
                                  return (
                                    <Button style={styles.buttonGo} onPress={() => this.props.navigation.navigate('cartScreen', {item: this.state.selectedBar,type:this.state.selectedBar.type})} success  full>
                                    <Text style={{ color:'white', fontSize: 20 }}>Review Order</Text>
                                  </Button>
                                  );
                                }
                            })()}

                            </Left>

            </Footer>
            </Container>


            );
      }
      }
    };

    var styles = StyleSheet.create({
      activeTabStyle: {
          backgroundColor:'#3F51B5',
          flex:1,
          height: 50,
          textAlign:'center',
          color:'white',
          justifyContent:'center',

      },
      container: {
        display: Platform.OS === 'web' ? 'block' : 'flex'
      },
      inactiveTabStyle: {
        backgroundColor:'#818cc4',
        flex:1,
        height: 50,
        textAlign:'center',
        color:'white',
        justifyContent:'center',
      },
      buttonGo: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize:18,
        borderRadius:10
  
    },centeredView: {
      justifyContent: "center",
      alignItems: "center",
      flex:1,
      backgroundColor: Platform.OS === 'web' ? '' : 'rgba(0, 0, 0, 0.5)'
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 2,
      paddingBottom:15,
      justifyContent:'center',
      minWidth:Dimensions.get('window').width - 20,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.75,
      shadowRadius: 3.84,
      elevation: 5
    },
    openButton: {
      backgroundColor: "#F194FF",
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    scrollableModal: {
      height: 300,
    },
    scrollableModalContent1: {
      height: 200,
      backgroundColor: '#87BBE0',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollableModalText1: {
      fontSize: 20,
      color: 'white',
    },
    scrollableModalContent2: {
      height: 200,
      backgroundColor: '#A9DCD3',
      alignItems: 'center',
      justifyContent: 'center',
    },
    scrollableModalText2: {
      fontSize: 20,
      color: 'white',
    },

    });

    const mapStateToProps = (state) => {
      return {
          cartItems: state,
          drinksmenu: state.drinksmenu,
          foodmenu: state.foodmenu,
          restaurant:state.restaurant,
          total: state.items.reduce(
            //reduce go through the array and cartItem is the each item in the array
            (accumulatedTotal, cartItem) =>
              accumulatedTotal + cartItem.price * cartItem.quantity,
            0 //0 is the start point of accumulatedTotal
          )
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
        selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
        addItemToCart: (product) => dispatch({ type: 'ADD_TO_CART', payload: product }),
        addFoodItemToCart: (product) => dispatch({ type: 'ADD_FOOD_TO_CART', payload: product }),
        removeItemFromCart: (product) => dispatch({ type: 'SUB_DRINK_QUANTITY', payload: product }),
        removeFoodItemFromCart: (product) => dispatch({ type: 'SUB_FOOD_QUANTITY', payload: product }),
        selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table }),
        setDrinksMenu: (drinks) => dispatch({ type: 'SET_DRINKS_MENU', payload: drinks }),
        setFoodMenu: (foods) => dispatch({ type: 'SET_FOOD_MENU', payload: foods }),
        setOrderType: (type) => dispatch({ type: 'SET_ORDER_TYPE', payload: type }),

      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(loadMenu))
