import React, { Component } from 'react';
import {  FlatList,  View, Text,TouchableOpacity,AppState,AsyncStorage, Vibration, Platform,ScrollView,RefreshControl,Alert,Button} from 'react-native';
import { Container, Thumbnail, Icon, List, ListItem, Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import {connect} from 'react-redux'
import Moment from 'moment';
import FooterBar from '../shared/Footer.js'
import HeaderBar from '../shared/Header.js'
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';

import Constants from 'expo-constants';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

class MyOrders extends Component {
    static navigationOptions = {
        title: 'My Orders',
        headerLeft: null,
        headerShown:false
      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          cid:'',
          token:'',
          refreshing:false,
          table:[],
          expoPushToken: '',
          notification: {},
          isLoading: true
        };
      }

      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
      changePage = (page) => {
        //alert(page)
    }
      async getToken(tokenName) {
        try {
          this.setState({ isLoading: true });

          let userData = await AsyncStorage.getItem(tokenName);
          let data = userData;
          if (tokenName == 'cid') { this.setState({cid: data}) }
          if (tokenName == 'token') { this.setState({token: data}, function() {

              fetch('https://tabledrop.app/api/order/?cid=' + this.state.cid + '&token=' + this.state.token)
              .then((response) => response.json())
              .then((json) => {
                this.setState({ data: json });
            })
              .catch((error) => console.error(error))
              .finally(() => {
                this.setState({ isLoading: false });
              });
            });
          }
        }
        catch (error) {
          console.log("Something went wrong", error);
          this.setState({ isLoading: false });
        }
      }



        registerForPushNotificationsAsync = async () => {
          if (Constants.isDevice) {
            const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted' || Platform.OS === 'android') {

              const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
              finalStatus = status;
            }
            if (finalStatus !== 'granted') {
              alert('You have disabled push notifications for TableDrop, we use these to send updates about your orders. Please enable in your Settings');
              return;
            }
            const { data: token } = await Notifications.getExpoPushTokenAsync();
            //alert(token);
            this.setState({ expoPushToken: token }, function() {
              fetch('https://tabledrop.com/api/setnotification/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cid: this.state.cid,
                    token: this.state.expoPushToken,
                 })
            })  .then((response) => response.json())
                .then((responseData) => {


                })
                .catch((error) =>{
                  console.error(error);
                })


            });
          } else {
            alert('Must use physical device for Push Notifications');
          }

          if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
              name: 'default',
              importance: Notifications.AndroidImportance.MAX,
              vibrationPattern: [0, 250, 250, 250],
              lightColor: '#FF231F7C',
            });
          }
        };

        componentDidMount() {
          this.getToken("cid");
          this.getToken("token");


          if (Platform.OS !== 'web') {
          // Handle notifications that are received or selected while the app
          // is open. If the app was closed and then opened by tapping the
          // notification (rather than just tapping the app icon to open it),
          // this function will fire on the next tick after the app starts
          // with the notification data.

          this.registerForPushNotificationsAsync();
          this.onReceivedListener = Notifications.addNotificationReceivedListener(this._handleNotification);



          }



        }


        _handleNotification = notification => {

              Vibration.vibrate()
              this.setState({ notification: notification }, function() {
                Alert.alert(
                  "Order Update",
                  this.state.notification.request.content.body,
                  [
                    { text: "OK", onPress: () => this.setState({notification:{}}) }
                  ]

                  );

              });


      };


      componentWillUnmount() {
          if (Platform.OS !== 'web') {
            this.onReceivedListener.remove();
          }
        }


        fetchOrders = () => {

          fetch('https://tabledrop.app/api/order/?cid=' + this.state.cid + '&token=' + this.state.token)
          .then((response) => response.json())
          .then((json) => {
            this.setState({ data: json });
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({ refreshing: false });

          });


        }
        _refreshingOrders = async () => {
          this.setState({ refreshing: true });
          this.fetchOrders();
        }



      render() {
        Moment.locale('en');
        const { data, isLoading,refreshing,notification} = this.state;
        const { navigation } = this.props;

        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue'/>
                </Container>
              );
        } else {

        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
          <Container>
          <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>


            {(() => {

            if (data.length == '0') {
                return (
                  <View style={{padding:20, textAlign: 'center'}}>
                      <Text style={{ textAlign: 'center'}}>You have not placed any TableDrop orders yet.</Text>
                  </View>);
            }


            })()}


            <ScrollView
            style={{marginTop:0}}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={this._refreshingOrders} />
            }>

        <FlatList
                data={data}
                keyExtractor={({ id }, index) => id}
                renderItem={({ item }) => (



                  <Card>
                  <CardItem>
                    <Left>
                        <Thumbnail source={{uri: item.restaurant_image}} />
                      <Body>
                        <Text>{item.restaurant_name}</Text>
                        <Text note>{Moment(item.created_at).fromNow()}</Text>
                      </Body>
                    </Left>
                    <Right>
                      {item.status == '1' &&
                        <Text style={{spaddingTop:2}}> In Progress</Text>
                      }
                      {item.status == '6' &&
                        <Icon  style={{color:'orange'}} type="FontAwesome5" name="clock" />
                      }
                      {item.status == '7' &&
                        <Text style={{color:'green'}}>Ready</Text>
                      }
                      {item.status == '3'  &&
                        <Icon  style={{color:'green'}} type="FontAwesome5" name="check" />
                      }
                      {item.status == '4'  &&
                        <Icon  style={{color:'red'}} type="FontAwesome5" name="cross" />
                      }

                    </Right>
                  </CardItem>
                  <CardItem cardBody>

                  <List style={{width:'100%'}}>
                            {item.orderitems.map((item, index) =>
                                <ListItem >
                                    <Body>
                                    <Text style={{fontSize:12}}>{item.quantity} x {item.itemname}</Text>
                                    </Body>
                                    <Right>
                                        <Text style={{fontSize:12}}>£{item.price}</Text>
                                    </Right>
                                </ListItem >
                            )}
                    </List>





                  </CardItem>
                  <CardItem>
                    <Left>
                      <Text>£{item.total_price}</Text>
                    </Left>
                    <Body>
                      <Text style={{color:'green'}}> {item.status == '7' && 'Ready for collection' } </Text>

                    </Body>
                    <Right>
                          <Text style={{fontSize:18}}>Order #{item.id}</Text>
                    </Right>
                  </CardItem>
                </Card>




               )}
              />

          </ScrollView>



            <FooterBar />
        </Container>
        </Drawer>
        );
     } // is loading
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
          selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table })
      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(MyOrders)
