import React, { Component } from 'react';
import { View, Text,TouchableOpacity, StyleSheet, Image,AsyncStorage,ScrollView,KeyboardAvoidingView, Platform,Animated,Keyboard,Dimensions} from 'react-native';
import { Container,  Icon, Button, Spinner, Form, Input, Label, Item,Toast,Root,Picker} from 'native-base';
import {connect} from 'react-redux'
import { withNavigation } from 'react-navigation'

class PhoneRegister extends Component {
    static navigationOptions = {
        headerShown: false,
        title: '',
      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          phone:'',
          name:'',
          phoneCountry:'+44',
          table:[],
          isLoading: true,
          verifyingNumber: false
        };
        this.keyboardHeight = new Animated.Value(0);
        this.imageHeight = new Animated.Value(250);

      }
      async storeToken(tokenName, tokenValue) {
        try {
           await AsyncStorage.setItem(tokenName, tokenValue);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }



      sendSMS() {
           if (this.state.phone && this.state.name) {
            this.setState({verifyingNumber: true});
            fetch('https://tabledrop.app/api/smsverify/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone: this.state.phoneCountry + this.state.phone,
                name:  this.state.name
            })
        })  .then((response) => response.json())
            .then((responseData) => {
                console.log("RESULTS HERE:", responseData)
                        this.setState({
                         isLoading: false
                    }, function(){
                        if (responseData.status == 'queued' || responseData.status == 'sent') {
                            this.props.navigation.navigate('CodeScreen', {phone: this.state.phoneCountry + this.state.phone,name:this.state.name})
                        }
                        else if (responseData.status == 'bypass') {
                            this.storeToken("cid",responseData.id.toString());
                            this.storeToken("token",responseData.token.toString());
                            this.storeToken("phone",this.state.phoneCountry + this.state.phone);

                            this.props.navigation.navigate('BarList')
                        }

                        else  {
                            alert('Problem Sending SMS Verification');
                        }
                    });
            })
            .catch((error) =>{
                console.error(error);
               // alert(error);
               Toast.show({
                text: "There was a problem verifying your number. Please check and try again",
                buttonText: "Okay",
                duration:3000
              })


                this.setState({verifyingNumber: false});

            })
        } else {
            Toast.show({
                text: "Please enter your phone number above so that we can send a verification SMS",
                buttonText: "Okay",
                duration:3000
              })
        }

    }


    componentDidMount () {
      this.keyboardWillShowSub = Keyboard.addListener('keyboardWillShow', this.keyboardWillShow);
      this.keyboardWillHideSub = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
    }

    componentWillUnmount() {
      this.keyboardWillShowSub.remove();
      this.keyboardWillHideSub.remove();
    }

    keyboardWillShow = (event) => {
      Animated.parallel([
        Animated.timing(this.keyboardHeight, {
          duration: event.duration,
          toValue: event.endCoordinates.height,
        }),
        Animated.timing(this.imageHeight, {
          duration: event.duration,
          toValue: 150,
        }),
      ]).start();
    };

    keyboardWillHide = (event) => {
      Animated.parallel([
        Animated.timing(this.keyboardHeight, {
          duration: event.duration,
          toValue: 0,
        }),
        Animated.timing(this.imageHeight, {
          duration: event.duration,
          toValue: 250,
        }),
      ]).start();
    };



    onValueChange = (value) => {
        this.setState({
            phoneCountry: value
        });
    }

      render() {
        const { phoneCountry } = this.state;



        return (
            <Root>
            <Container>
              <KeyboardAvoidingView
  behavior={Platform.OS == "ios" ? "padding" : "height"}
  style={styles.container}
>
            <ScrollView contentContainerStyle={{flexGrow: 1}}
              keyboardShouldPersistTaps='handled' style={styles.container}>
                <View style={{flex:1}}>
                <View style={{flex:1.5, alignItems:'center',justifyContent:'flex-end'}}>
                <Image
                      source={require('../assets/logo.jpg')}
                      style={styles.logo}
                  />
                </View>
                <View style={{flex:2,alignItems:'center',justifyContent:'center'}}>

                    <Animated.Image
                        source={require('../assets/illustration.png')}
                        style={[styles.img, { height: this.imageHeight }]}
                        resizeMode='contain'
                    />

                </View>
                <View style={{flex:4, justifyContent:'flex-start',paddingLeft:10,paddingRight:10}}>

                    <Text style={styles.center}>Please enter your name and mobile phone number to get started with TableDrop.</Text>


                    <Form style={styles.form}>
                    <Item rounded >
                        <Icon active name='person' style={styles.phone} />
                    <Input  returnKeyType={ 'done' } onChangeText={(name) => this.setState({name})}  placeholder='Your Name' style={styles.number}/>
                    </Item>

                        <Item rounded style={{marginTop:10}}>
                        <Icon active name='call' style={styles.phone} />



                        <Picker note  style={styles.picker} selectedValue={phoneCountry} onValueChange={this.onValueChange.bind(this)} >
                        <Picker.Item label="AU +61" value="+61" />
                        <Picker.Item label="IE +1" value="+353" />
                        <Picker.Item label="NZ +64" value="+64" />
                        <Picker.Item label="UK +44" value="+44" />
                        <Picker.Item label="US +1" value="+1" />
                        </Picker>


                        <Input  returnKeyType={ 'done' } onChangeText={(phone) => this.setState({phone})} keyboardType={'phone-pad'} placeholder='Your Phone Number' style={styles.number}/>
                        </Item>

                        {(() => {
                            if ( this.state.verifyingNumber ) {
                                return (
                                    <Spinner color='white' />
                                )
                                } else {
                                return(
                                    <Button
                                    onPress={() =>this.sendSMS()}
                                info large  style={styles.buttonGo} ><Text> Verify My Number </Text></Button>
                                )
                                }
                    })()}


                    </Form>
                </View>
</View>
            </ScrollView>
          </KeyboardAvoidingView>
            </Container></Root>
        );
      }
    };

    var styles = StyleSheet.create({
        logo: {
            width:300,
            height:60,
            marginTop:30
        },
        form: {
            marginTop:30
        },
        img: {
            width:234,
            height:249,
        },

        phone: {
            color:'white',
            marginTop:10
        },
        number: {
            color:'white',
            flex:3,
        },
        picker: {
            backgroundColor: '#1C2B48',
            width:90,
            height:22,
            flex:1,
            color:'#fff',

        },
        container: {
             flex: 1,
             backgroundColor: '#1C2B48',
             color:'#fff',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            paddingTop:20,
            fontSize:18,
        },
        buttonGo: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginTop:40,
            fontSize:18,
            borderRadius:10

        }
    });


    const mapStateToProps = (state) => {
      return {
          table: state.table
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
          selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table })
      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(PhoneRegister))
