import React, { Component } from 'react';
import { View, Text,AsyncStorage,Alert,StyleSheet,Platform,ScrollView } from 'react-native'
import {connect} from 'react-redux'
import { Container, Spinner, Item, Icon, Picker, Left, Body, Textarea, Input, Right, H2, Radio, Button,Form, List, ListItem,  Content,  Footer } from 'native-base';
import CartIcon from '../components/CartIcon.js'
import { withNavigation } from 'react-navigation'

class CartScreen extends Component {
    static navigationOptions = {
        title: 'Review Order',
        headerRight:<CartIcon/>
      };

  constructor(props) {
    super(props);
    this.state = {
        isLoading:false,
        cid:'',
        token:'',
        expoPushToken: '',
        phone:'',
        name:'',
        phoneNo:'',
        notes:'',
        selectedBar: props.navigation.state.params,
        paymentMethod:'byapp',
        orderType:''
    };
  }

  async storeToken(tokenName, tokenValue) {
    try {
       await AsyncStorage.setItem(tokenName, tokenValue);
    } catch (error) {
      console.log("Something went wrong", error);
    }
  }

  async getToken(tokenName) {
    try {
      let userData = await AsyncStorage.getItem(tokenName);
      let data = userData;
      if (tokenName == 'cid') { this.setState({cid: data}) }
      if (tokenName == 'token') { this.setState({token: data}) }
      if (tokenName == 'phone') { this.setState({phone: data}) }
    } catch (error) {
      alert(error);
      console.log("Something went wrong", error);
    }
  }




  componentDidMount() {
    this.getToken("cid");
    this.getToken("token");
    this.getToken("phone");

    if(! this.props.restaurant.acceptPayByApp) {
      this.setState({paymentMethod:'card'})
    }


  }


      confirmPlaceOrder() {
        if (Platform.OS === 'web' || this.state.paymentMethod == 'byapp') {
          this.placeOrder()
       } else {
        Alert.alert(
          'Send Order',
          'This will send your order and it can\'t be cancelled.',
          [
            {text: 'Cancel'},
            {text: 'Send Order', onPress:() => this.placeOrder() },
          ]
        );
          }
      }

      placeOrder() {

          this.setState({isLoading: true});
          let items = JSON.stringify(this.props.cartItems);

          if (this.state.paymentMethod && (this.state.cid || this.state.phoneNo) && this.props.cartItems.length > 0) {
           // fetch('http://10.0.2.2:8000/api/submitorder/', {
            fetch('https://tabledrop.app/api/submitorder/', {
            method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  items: items,
                  table: this.props.table.id,
                  cid: this.state.cid,
                  token: this.state.token,
                  restaurant: this.props.restaurant.id,
                  name:this.state.name,
                  paymentMethod:this.state.paymentMethod,
                  phone:this.state.phoneNo,
                  notes: this.state.notes,
                  type:this.props.orderType
              })
          })  .then((response) => response.json())
              .then((responseData) => {
                  console.log("RESULTS HERE:", responseData)
                          this.setState({
                          isLoading: false
                      }, function(){
                          console.log(responseData)
                          if (responseData.status == 'success') {
                              this.props.emptyCart(this.props.restaurant);
                              if (this.state.paymentMethod == 'byapp') {
                                this.props.navigation.navigate('PaymentScreen', {payment: responseData.paymentsess,accId: responseData.accId,pintent: responseData.pintent} )
                              } else {
                                this.props.navigation.navigate('myOrders')
                              }
                            } else if (responseData.status == 'qrcustomer') {
                              this.storeToken("cid",responseData.cid.toString());
                              this.storeToken("token",responseData.token.toString());
                              this.storeToken("phone",this.state.phoneNo);
                              this.props.emptyCart(this.props.restaurant);
                            if (this.state.paymentMethod == 'byapp') {
                              this.props.navigation.navigate('PaymentScreen', {payment: responseData.paymentsess,accId: responseData.accId,pintent: responseData.pintent} )
                            } else {
                            this.props.navigation.navigate('myOrders')
                            }
                          } else {
                              alert('Problem Sending Order');
                              this.setState({isLoading: false});

                          }
                      });
              })
              .catch((error) =>{
                alert('Problem Sending Order' + error );
                this.setState({isLoading: false});

                console.error(error);
              })
        } else {
          alert('Please ensure you have entered your mobile number and selected your payment method.');
          this.setState({isLoading: false});

        }
    }

    onValueChange2(value) {
      this.setState({
        paymentMethod: value
      });
    }

  render() {
    const table = this.state.table
    const isLoading = this.state.isLoading





    if (isLoading) {
      return  (
        <View style={{justifyContent:'center',flexDirection: 'column', flex:1, textAlign: 'center'}}>
            <Spinner color='blue'/>
        </View>
      );
    }
    else {

    return (
     <Container style={styles.container}>
        <ScrollView>



        <View style={{flexDirection:'column',flex:1}}>
        <View>
            {(() => {
                  if (this.props.orderType == 'tableservice') {
                      return (
                        <H2  style={{ textAlign: 'center',marginTop:20}}>
                        Ordering to Table: {this.props.table.number}
                        </H2>
                      )
                  }
                  if (this.props.orderType == 'collection') {
                    return (
                      <View>
                        <H2  style={{ textAlign: 'center',marginTop:20}}>
                      Ordering for Collection
                      </H2>
                        <Text style={{padding:10,textAlign:'center'}}>We will send you a notification when this order is ready for collection. You can also monitor on the 'My Orders' tab.</Text>
                      </View>
                    )
                }
           })()}

      </View>



         <View>

          <List>
            {this.props.cartItems.map((item, index) =>
              <ListItem >
                <Body>
                <Text>{item.name}</Text>
                <Text note numberOfLines={1}>Price: {this.props.restaurant.currency}{item.price}</Text>
                </Body>
                <Right>
                    <Text>{item.quantity}</Text>
                </Right>
              </ListItem >
            )}
          </List>

      {(() => {
        if (this.props.total > 0) {
          return (

            <Text style={{  fontSize: 20, marginRight:15,marginTop:15,textAlign:'right'}} >Total: {this.props.restaurant.currency}{this.props.total.toFixed(2)} </Text>
          )
        }
      })()}

        </View>

        <View>


        {(() => {
                  if (this.props.restaurant.acceptCash) {
                    return (
                    <ListItem onPress={() => this.setState({ paymentMethod: 'cash' })} >
                      <Left>
                      <Text>Pay venue directly with Cash</Text>
                    </Left>
                    <Right>
                    <Radio selected={(this.state.paymentMethod == 'cash')} onPress={() => this.setState({ paymentMethod: 'cash' })} />
                    </Right>
                  </ListItem>
                      );
                  }
             })()}
        {(() => {
                  if (this.props.restaurant.acceptCard) {
                    return (
                    <ListItem onPress={() => this.setState({ paymentMethod: 'card' })}>
                      <Left>
                      <Text>Pay venue directly by Card</Text>
                    </Left>
                    <Right>
                    <Radio selected={(this.state.paymentMethod == 'card')}  onPress={() => this.setState({ paymentMethod: 'card' })} />
                    </Right>
                  </ListItem>
                      );
                  }
             })()}
        {(() => {
                  if (this.props.restaurant.acceptPayByApp) {
                    return (
                    <ListItem onPress={() => this.setState({ paymentMethod: 'byapp' })} >
                      <Left>
                      <Text>Pay now in the App</Text>
                    </Left>
                    <Right>
                      <Radio selected={(this.state.paymentMethod == 'byapp')} onPress={() => this.setState({ paymentMethod: 'byapp' })} />
                    </Right>
                  </ListItem>
                      );
                  }
             })()}









    </View>


       <View style={{flex:1}}>

            {(() => {
                  if (this.props.cartItems.length == 0 && !isLoading) {
                    return (
                      <Text style={{textAlign:'center',padding:10,marginTop:20}}>You have no items in your cart.</Text>
                    );
                  }
                })()}

            {(() => {
              if (this.props.cartItems.length > 0 && !isLoading) {
                return (
                  <Form>
                    <Content padder style={{marginTop:10}}>
                        <Form>
                           <Textarea  onChangeText={(notes) => this.setState({ notes: notes })} rowSpan={5} bordered placeholder="Optional notes, please include any additional details or food requirements." />
                        </Form>
                    </Content>
                  </Form>
                 );
                }
               })()}

              {(() => {

                  if (this.state.phone) {
                      return (
                       <Text style={{textAlign:'center', marginTop:20}}>Ordering as {this.state.phone}</Text>
                      )
                      } else {
                        return (
                          <View>
                          <Item regular>
                              <Input  style={{borderColor:'red'}} onChangeText={(name) => this.setState({ name: name })} rowSpan={5} bordered placeholder="Enter your name" />
                          </Item>
                          <Item regular>
                              <Input  style={{borderColor:'red'}} onChangeText={(phoneNo) => this.setState({ phoneNo: phoneNo })} rowSpan={5} bordered placeholder="Enter your phone number" />
                          </Item>
                          </View>
                        )
                      }

                })()}

               </View>
        </View>
        </ScrollView>
        <Footer>
            <Left>
                <Text style={{  fontSize: 20 }} >Total: {this.props.restaurant.currency}{this.props.total.toFixed(2)} </Text>
            </Left>
            <Right>

            {(() => {
              if (this.props.cartItems.length > 0 && !isLoading) {
                return (
                  <Button onPress={() => this.confirmPlaceOrder()} success style={styles.buttonGo} full>
                  <Text style={{ color:'white', fontSize: 20 }}>Place My Order</Text>
                  </Button>
                 );
              }
             })()}

            </Right>
         </Footer>
      </Container>
    );
            } // end if loading
  }
}

var styles = StyleSheet.create({
   container: {
    display: Platform.OS === 'web' ? 'block' : 'flex'
  }
});


const mapStateToProps = (state) => {
    return {
        cartItems: state.items,
        table:state.table,
        restaurant: state.restaurant,
        orderType:state.orderType,
        total: state.items.reduce(
            //reduce go through the array and cartItem is the each item in the array
            (accumulatedTotal, cartItem) =>
              accumulatedTotal + cartItem.price * cartItem.quantity,
            0 //0 is the start point of accumulatedTotal
          ),
        qty: state.quantity
    }
}

var styles = StyleSheet.create({
  buttonGo: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize:18,
      borderRadius:10

  }
});



  const mapDispatchToProps = (dispatch) => {
    return {
        addItemToCart: (product) => dispatch({ type: 'ADD_TO_CART', payload: product }),
        removeItemFromCart: (product) => dispatch({ type: 'REMOVE_FROM_CART', payload: product }),
        emptyCart: (bar) => dispatch({ type: 'EMPTY_CART', payload: bar })

    }
}
  export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(CartScreen))
