import React, { Component } from 'react';
import {  View, AsyncStorage,Switch } from 'react-native';
import { Container,  Button, Content, Thumbnail, Item, Input, Text,  Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import { withNavigation } from 'react-navigation'
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';


class BarSettings extends Component {
  static navigationOptions = {
        headerShown: false,
      };
  constructor(props) {
        super(props);
        this.state = {
          data: [],
          restaurant:[],
          currentOrientation: '',
          acceptingOrders:false,
          isLoading: true,
          soundAlerts:'on',
          ordersWithin:'',
          loadingStats: true,
          acceptTableService:false,
          acceptCollection:false,
          collectionsWithin:''
        };
      }

  readData = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            // Fetch the bartoken to see if there is an authenticated bar.
            if (tokenName === 'bartoken') { this.props.setBarToken(tokenValue);  }
            // Fetch the cid, the phone identifier/customer identifier for all users.
            if (tokenName === 'cid') {  this.props.setCID(tokenValue); this.setState({cid: tokenValue})  }
            if (tokenName === 'soundAlerts') {
                   this.setState({soundAlerts: tokenValue})
              }
          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      async storeToken(tokenName, tokenValue) {
        try {
           await AsyncStorage.setItem(tokenName, tokenValue);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }


      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
      changePage = (page) => {
        //alert(page)
    }

fetchRestaurant() {
    fetch('https://tabledrop.app/api/managerestaurant/', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + this.props.bartoken
        }
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({restaurant: json[0]})
        this.setState({acceptingOrders: this.state.restaurant.acceptingOrders})
        this.setState({acceptCollection: this.state.restaurant.acceptCollection})
        this.setState({acceptTableService: this.state.restaurant.acceptTableService})


        this.setState({ordersWithin: this.state.restaurant.ordersWithin})
        this.setState({collectionsWithin: this.state.restaurant.collectionsWithin})
        this.props.selectBar(json[0]);
        this.setState({ isLoading: false });
      })

}

  async componentDidMount() {
        this.readData("cid");
        this.readData("bartoken");
        this.readData("soundAlerts");


        await Font.loadAsync({
          Roboto: require('native-base/Fonts/Roboto.ttf'),
          Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
          ...Ionicons.font,
        });
        if (this.props.bartoken) {
          //this.setState({ isLoading: false });
        }

        this.fetchRestaurant();



  }

  updateRestaurant = () => {
    this.setState({isLoading: true});
    fetch('https://tabledrop.app/api/managerestaurant/' + this.state.restaurant.id + '/', {
      method: 'PUT',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.props.bartoken
      },
      body: JSON.stringify({
          acceptingOrders: this.state.acceptingOrders,
          ordersWithin: this.state.ordersWithin,
          acceptCollection: this.state.acceptCollection,
          acceptTableService: this.state.acceptTableService,
          collectionsWithin: this.state.collectionsWithin
      })
  })
    .then((response) => response.json())
    .then((json) => {
          //do something with response 'json'
    })
    .catch((error) => console.error(error))
    .finally(() => {
        this.fetchRestaurant()
        //this.setState({isLoading: false});
     });
     //this.setState({isLoading: false});

  }

  toggleSwitch = (value) => {
        this.setState({acceptingOrders: !this.state.acceptingOrders}, function () {
            this.setState({isLoading: true});
            this.updateRestaurant()
        });
    }

    toggleTableService = (value) => {
      this.setState({acceptTableService: !this.state.acceptTableService}, function () {
          this.setState({isLoading: true});
          this.updateRestaurant()
      });
  }

    toggleCollections = (value) => {
      this.setState({acceptCollection: !this.state.acceptCollection}, function () {
          this.setState({isLoading: true});
          this.updateRestaurant()
      });
  }

    toggleSwitchSound = (value) => {

      let newValue = (value) ? 'on' : 'off'
      //alert(newValue)
      this.setState({soundAlerts: newValue}, function () {
          this.storeToken('soundAlerts',newValue);
      });
  }


   updateRange = (value) => {
        //alert(value)
        if (this.state.ordersWithin < 250) {
            this.setState({isLoading: true});
            this.updateRestaurant()
        } else {
            alert('Table Service: Maximum of 250m')
        }

 }


      render() {
        const { isLoading,stats,loadingStats,soundAlerts,restaurant,acceptingOrders,ordersWithin,acceptTableService,acceptCollection   } = this.state;




        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
                        
        <Container>
        <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
            <Content>
            <View>


          {(() => {

          if (isLoading) {
              return  (
                    <View style={{justifyContent:'center',flexDirection: 'column', flex:1, textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                  );
            } else {


              return  (
                <View>
                <Card transparent>
                    <CardItem>
                    <Left>
                    <Thumbnail source={{uri: restaurant.image}} />
                        <Body>
                            <Text> {restaurant.name}</Text>
                            <Text note>Order Console </Text>
                        </Body>
                    </Left>
                    <Right>

                    </Right>
                    </CardItem>
                </Card>

                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Accepting Orders  </Text>

                                </View>
                                <View style={{flex:3,marginLeft:10,textAlign:'left',justifyContent:'flex-start'}}>

                                <Switch
                                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                                    thumbColor={acceptingOrders ? "#f5dd4b" : "#f4f3f4"}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={this.toggleSwitch}
                                    value={acceptingOrders}
                                    style={{alignSelf:"flex-start"}}
                                />

                                {acceptingOrders ? (
                                    <Text>You are currently accepting orders</Text>
                                ): (
                                    <Text>You have switched off TableDrop orders</Text>
                                )}
                                </View>

                    </View>

                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Enable Table Service  </Text>

                                </View>
                                <View style={{flex:3,marginLeft:10,textAlign:'left',justifyContent:'flex-start'}}>

                                <Switch
                                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                                    thumbColor={acceptTableService ? "#f5dd4b" : "#f4f3f4"}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={this.toggleTableService}
                                    value={acceptTableService}
                                    style={{alignSelf:"flex-start"}}
                                />

                                </View>

                    </View>
                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Accept Table Service Orders Within (meters)  </Text>
                                </View>
                                <View style={{flex:3,marginLeft:10}}>
                                    <Text>{restaurant.ordersWithin} meters</Text>
                                    <Item rounded>
                                        <Input  returnKeyType={ 'done' } onChangeText={(ordersWithin) => this.setState({ordersWithin})} value={`${this.state.ordersWithin}`} keyboardType={'numeric'}  />
                                    </Item>
                                 <Text>This allows customers using TableDrop to place a table service order only if their Geo Location is within this limit. This can be changed, for example to 150m if you have a large outdoor service area, or 50m if you are a small venue.</Text>

                                </View>


                    </View>

                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Enable Collections  </Text>

                                </View>
                                <View style={{flex:3,marginLeft:10,textAlign:'left',justifyContent:'flex-start'}}>

                                <Switch
                                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                                    thumbColor={acceptCollection ? "#f5dd4b" : "#f4f3f4"}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={this.toggleCollections}
                                    value={acceptCollection}
                                    style={{alignSelf:"flex-start"}}
                                />


                                </View>

                    </View>
                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Accept Collection Orders From Customers Within (meters)  </Text>
                                </View>
                                <View style={{flex:3,marginLeft:10}}>
                                    <Text>{restaurant.collectionsWithin} meters</Text>
                                    <Item rounded>
                                        <Input  returnKeyType={ 'done' } onChangeText={(collectionsWithin) => this.setState({collectionsWithin})} value={`${this.state.collectionsWithin}`} keyboardType={'numeric'}  />
                                    </Item>
                                 <Text>This allows customers using TableDrop to place a collection order only if their Geo Location is within this limit (meters).</Text>

                                </View>


                    </View>


                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> New Order Sound Alert  </Text>

                                </View>
                                <View style={{flex:3,marginLeft:10,textAlign:'left',justifyContent:'flex-start'}}>

                                <Switch
                                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                                    thumbColor={acceptingOrders ? "#f5dd4b" : "#f4f3f4"}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={this.toggleSwitchSound}
                                    value={soundAlerts === 'on' ? true : false}
                                    style={{alignSelf:"flex-start"}}
                                />

                                    <Text>Plays a sound when new orders come in and you are on the Order Console Pending Orders</Text>

                                </View>

                    </View>



                    <View style={{flexDirection:'row',marginTop:15}}>

                            <View style={{flex:2,marginLeft:10}}>
                          </View>
                                 <View style={{flex:3,marginLeft:10,marginBottom:10,textAlign:'left'}}>

                                    <Button onPress={() =>this.updateRange()}  info  rounded ><Text> Save Distance </Text></Button>

                                </View>

                 </View>


                <View>
                </View>
                </View>
                    )}
            })()}

        </View>
        </Content>
       <FooterBar />
       </Container>
       </Drawer>
        );
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant,
      }
  }

    const mapDispatchToProps = (dispatch) => {
      return {
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
          setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
          setCID: (token) => dispatch({ type: 'SET_CID_TOKEN', payload: token })
      }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(BarSettings))
