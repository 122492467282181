import React, { Component } from 'react';
import {
  Text,StyleSheet
} from 'react-native';
import { withNavigation } from 'react-navigation'
import {connect} from 'react-redux'
import {Content, List, ListItem, Icon, Left, Body, Button} from 'native-base';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          cid:'',
          username:'',
          password:'',
          table:[],
          isLoading: true,
          loggedIn:false,
          bartoken:''
        };
      }

  closeAndNavigate(to) {
    this.props.closeBar()
    if (to == 'privacy') {
        this.props.changePage('privacy')
        this.props.navigation.navigate('WebScreen', {page: 'privacy'})
    } else if (to == 'terms') {
        this.props.changePage('terms')
        this.props.navigation.navigate('WebScreen', {page: 'terms'})
    } else if (to == 'venue') {
        this.props.changePage('venue')
        this.props.navigation.navigate('WebScreen', {page: 'venue'})
    }  else if (to == 'feedback') {
        this.props.changePage('feedback')
        this.props.navigation.navigate('WebScreen', {page: 'feedback'})
    } else if (to == 'orderconsole') {
        this.props.navigation.navigate('BarOrders',{'filter':'pending'})
    } else {
    this.props.navigation.navigate(to)
    }

}


  render() {
    return (
          <Content style={styles.sidebar}>
    {(() => {
          if ( this.props.bartoken ) {
          return (
            <List>
            <ListItem icon onPress={() => this.closeAndNavigate('BarDashboard')}>
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
            <Icon name="chart-bar" type="FontAwesome5"/>
                </Button>
            </Left>
            <Body>
                 <Text style={styles.text}>Dashboard</Text>
            </Body>
            </ListItem>


            <ListItem icon onPress={() => this.closeAndNavigate('orderconsole')}>
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="cart" />
                </Button>
            </Left>
            <Body>
                 <Text style={styles.text}>Order Console</Text>
            </Body>
            </ListItem>



            <ListItem icon onPress={() => this.closeAndNavigate('BarSettings')}>
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="cog" />
                </Button>
            </Left>
            <Body>
                 <Text style={styles.text}>Settings</Text>
            </Body>
            </ListItem>


            <ListItem icon onPress={() => this.closeAndNavigate('BarStock')}>
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
            <Icon name="layer-group" type="FontAwesome5"/>
                </Button>
            </Left>
            <Body>
                 <Text style={styles.text}>Manage Stock</Text>
            </Body>
            </ListItem>


            <ListItem  icon onPress={() => this.closeAndNavigate('feedback')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="mail" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>Send Us Feedback</Text>
            </Body>
            </ListItem>
            </List>

            );
          } else {

            return (


            <List>
            <ListItem icon onPress={() => this.closeAndNavigate('BarList')}>
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="home" />
                </Button>
            </Left>
            <Body>
                 <Text style={styles.text}>Find Nearby Venues</Text>
            </Body>
            </ListItem>
            <ListItem  icon onPress={() => this.closeAndNavigate('myOrders')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="cart" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>View My Orders</Text>
            </Body>
            </ListItem>
            <ListItem  icon onPress={() => this.closeAndNavigate('venue')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="add" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>Add Venue to TableDrop</Text>
            </Body>
            </ListItem>

            <ListItem  icon onPress={() => this.closeAndNavigate('terms')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="paper" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>Terms and Conditions</Text>
            </Body>
            </ListItem>

            <ListItem  icon onPress={() => this.closeAndNavigate('privacy')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="paper" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>Privacy Policy</Text>
            </Body>
            </ListItem>

            <ListItem  icon onPress={() => this.closeAndNavigate('feedback')} >
            <Left>
            <Button style={{ backgroundColor: "#007AFF" }}>
                <Icon active name="mail" />
                </Button>
            </Left>
             <Body>
                  <Text style={styles.text}>Send Us Feedback</Text>
            </Body>
            </ListItem>

          </List>
            )
         }
        })()}

          </Content>
    );
  }
}


var styles = StyleSheet.create({
    sidebar: {
        backgroundColor:'#1C2B48',
        paddingTop:30
    },
    text: {
        color:'#ffffff',
    }
})
const mapStateToProps = (state) => {
    return {
        bartoken: state.bartoken
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
        selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table }),
        setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),

    }
  }
  //module.exports = Sidebar;

  export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(Sidebar))
