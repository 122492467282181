import React, { Component } from 'react';
import {AsyncStorage,StatusBar, Platform } from 'react-native';

import { Container, Spinner} from 'native-base';
import BarList from './components/BarList.js'
import loadMenu from './components/LoadMenu.js'
import selectTable from './components/SelectTable.js'
import CartIcon from './components/CartIcon.js'
import CartScreen from './components/CartScreen.js'
import MyOrders from './components/MyOrders.js'
import PhoneRegister from './components/PhoneRegister.js'
import CodeScreen from './components/CodeScreen.js'
import BarLogin from './components/BarLogin.js'
import BarOrders from './components/BarOrders.js'
import ViewOrder from './components/ViewOrder.js'
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import {Provider} from 'react-redux'
import store from './store'
import BarDashboard from './components/BarDashboard.js';
import BarSettings from './components/BarSettings.js';
import BarStock from './components/BarStock.js';
import BarHours from './components/BarHours.js';
import PaymentScreen from './components/PaymentScreen.js';
import WebScreen from './components/WebScreen.js';




export default class App extends Component {

  constructor(props) {
      super(props);
      this.state = {
        initialRoute:'PhoneRegister',
        phoneVerified:false,
        bartoken:'',
        isLoading:true,
        QRCodeUser:false,
        table:'',
        venue:''
      };
    }

    async readData(tokenName) {
        try {
          let userData = await AsyncStorage.getItem(tokenName);
          let tokenValue = userData;
          if (tokenName == "cid" && tokenValue) { this.setState({phoneVerified:true}) }
          if (tokenName == "bartoken" && tokenValue) { this.setState({bartoken:tokenValue});  /*this.props.setBarToken(tokenValue);*/ }

        } catch (error) {
          console.log("Something went wrong", error);
        }
      }

    async componentDidMount() {

    //alert(window.location.href)

    if (Platform.OS === 'web') {

      let table = getParameterByName('t');
      let venue = getParameterByName('venue');

      if (table && venue) {
        this.setState({QRCodeUser:true})
        this.setState({table:table})
        this.setState({venue:venue})


      }

      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  }



    this.readData("cid");
    this.readData("bartoken");

    await Font.loadAsync({
      Roboto: require('native-base/Fonts/Roboto.ttf'),
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      ...Ionicons.font,
    });

    this.setState({isLoading:false})

  }

  LoggedInAppUser = createStackNavigator({
      PhoneRegister: {screen: PhoneRegister},
      BarList: {screen: BarList},
      BarDashboard: {screen: BarDashboard},
      BarSettings: {screen: BarSettings},
      BarStock: {screen: BarStock},
      BarHours: {screen: BarHours},
      selectTable: {screen: selectTable},
      loadMenu: {screen: loadMenu},
      CodeScreen: {screen: CodeScreen},
      cartScreen: {screen: CartScreen},
      PaymentScreen: {screen: PaymentScreen},
      myOrders: {screen: MyOrders},
      BarLogin: {screen: BarLogin},
      ViewOrder: {screen: ViewOrder},
      WebScreen: {screen: WebScreen},
      BarOrders: {screen: BarOrders}
      }, {
        initialRouteName: 'BarList'
      }
  )

  LoggedInAppBar = createStackNavigator({
    PhoneRegister: {screen: PhoneRegister},
    BarList: {screen: BarList},
    BarDashboard: {screen: BarDashboard},
    BarSettings: {screen: BarSettings},
    BarStock: {screen: BarStock},
    BarHours: {screen: BarHours},
    selectTable: {screen: selectTable},
    loadMenu: {screen: loadMenu},
    CodeScreen: {screen: CodeScreen},
    cartScreen: {screen: CartScreen},
    PaymentScreen: {screen: PaymentScreen},
    myOrders: {screen: MyOrders},
    BarLogin: {screen: BarLogin},
    WebScreen: {screen: WebScreen},
    ViewOrder: {screen: ViewOrder},
    BarOrders: {screen: BarOrders}
    }, {
      initialRouteName: 'BarDashboard'
    }
)


  newUserApp  = createStackNavigator({
      PhoneRegister: {screen: PhoneRegister},
      BarList: {screen: BarList},
      BarDashboard: {screen: BarDashboard},
      BarSettings: {screen: BarSettings},
      BarStock: {screen: BarStock},
      BarHours: {screen: BarHours},
      selectTable: {screen: selectTable},
      loadMenu: {screen: loadMenu},
      CodeScreen: {screen: CodeScreen},
      cartScreen: {screen: CartScreen},
      PaymentScreen: {screen: PaymentScreen},
      myOrders: {screen: MyOrders},
      WebScreen: {screen: WebScreen},
      ViewOrder: {screen: ViewOrder},
      BarLogin: {screen: BarLogin},
      BarOrders: {screen: BarOrders}
    }, {
      initialRouteName: 'PhoneRegister'
    }
  )


  QRCodeUser  = createStackNavigator({
    PhoneRegister: {screen: PhoneRegister},
    BarList: {screen: BarList},
    BarDashboard: {screen: BarDashboard},
    BarSettings: {screen: BarSettings},
    BarStock: {screen: BarStock},
    BarHours: {screen: BarHours},
    selectTable: {screen: selectTable},
    loadMenu: {screen: loadMenu},
    CodeScreen: {screen: CodeScreen},
    cartScreen: {screen: CartScreen},
    PaymentScreen: {screen: PaymentScreen},
    myOrders: {screen: MyOrders},
    WebScreen: {screen: WebScreen},
    ViewOrder: {screen: ViewOrder},
    BarLogin: {screen: BarLogin},
    BarOrders: {screen: BarOrders}
  }, {
    initialRouteName: 'loadMenu'
  }
)

  LoggedInAppUserNav = createAppContainer(this.LoggedInAppUser);
  newUserNav = createAppContainer(this.newUserApp);
  LoggedInAppBarNav = createAppContainer(this.LoggedInAppBar);
  QRCodeNav = createAppContainer(this.QRCodeUser);




  render() {
    const {  isLoading, QRCodeUser,table,venue } = this.state;

    if (isLoading) {
      return  (
            <Container style={{justifyContent:'center', textAlign: 'center'}}>
            <Spinner color='blue' />
            </Container>
          );
    } else {


          if (this.state.QRCodeUser) {
            return (
            <Provider store={store}>
            <StatusBar barStyle = "light-content" hidden = {false} backgroundColor = "#1C2B48" translucent = {false}/>
              <this.QRCodeNav props={{venue:this.state.venue,table:this.state.table}}/>
            </Provider>
            )

          } else {

                if (this.state.phoneVerified  ){

                  if (this.state.bartoken  ){
                    return (
                      <Provider store={store}>
                      <StatusBar barStyle = "light-content" hidden = {false} backgroundColor = "#1C2B48" translucent = {false}/>
                        <this.LoggedInAppBarNav />
                    </Provider>
                      )
                  } else {
                    return (
                      <Provider store={store}>
                      <StatusBar barStyle = "light-content" hidden = {false} backgroundColor = "#1C2B48" translucent = {false}/>
                        <this.LoggedInAppUserNav />
                    </Provider>
                      )

                  }


                } else {
                  return(
                    <Provider store={store}>
                        <this.newUserNav />
                    </Provider>)
                }
          }



        }


  }
}
