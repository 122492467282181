import React, { Component } from 'react';
import {  View, AsyncStorage,Switch } from 'react-native';
import { Container,  Button, Content, Thumbnail, Item, Input, Text,  Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import { withNavigation } from 'react-navigation'
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import {connect} from 'react-redux'
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';


class BarHours extends Component {
  static navigationOptions = {
        headerShown: false,
      };
  constructor(props) {
        super(props);
        this.state = {
          data: [],
          restaurant:[],
          currentOrientation: '',
          acceptingOrders:false,
          isDatePickerVisible:false,
          isLoading: true,
          soundAlerts:'on',
          ordersWithin:'',
          loadingStats: true,
          acceptTableService:false,
          acceptCollection:false,
          collectionsWithin:''
        };
      }


      

  readData = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            // Fetch the bartoken to see if there is an authenticated bar.
            if (tokenName === 'bartoken') { this.props.setBarToken(tokenValue);  }
            // Fetch the cid, the phone identifier/customer identifier for all users.
            if (tokenName === 'cid') {  this.props.setCID(tokenValue); this.setState({cid: tokenValue})  }

          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      async storeToken(tokenName, tokenValue) {
        try {
           await AsyncStorage.setItem(tokenName, tokenValue);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }


      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
    

fetchRestaurant() {
    fetch('https://tabledrop.app/api/managerestaurant/', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + this.props.bartoken
        }
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({restaurant: json[0]})
        this.props.selectBar(json[0]);
        this.setState({ isLoading: false });
      })

}

  async componentDidMount() {
        this.readData("cid");
        this.readData("bartoken");

        await Font.loadAsync({
          Roboto: require('native-base/Fonts/Roboto.ttf'),
          Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
          ...Ionicons.font,
        });

        this.fetchRestaurant();



  }

  updateRestaurant = () => {
    this.setState({isLoading: true});
    fetch('https://tabledrop.app/api/managerestaurant/' + this.state.restaurant.id + '/', {
      method: 'PUT',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.props.bartoken
      },
      body: JSON.stringify({
          acceptingOrders: this.state.acceptingOrders,
          ordersWithin: this.state.ordersWithin,
          acceptCollection: this.state.acceptCollection,
          acceptTableService: this.state.acceptTableService,
          collectionsWithin: this.state.collectionsWithin
      })
  })
    .then((response) => response.json())
    .then((json) => {
          //do something with response 'json'
    })
    .catch((error) => console.error(error))
    .finally(() => {
        this.fetchRestaurant()
        //this.setState({isLoading: false});
     });
     //this.setState({isLoading: false});

  }


    showDatePicker() {
        this.setState({isDatePickerVisible: true})
    };
    
    hideDatePicker() {
        this.setState({isDatePickerVisible: false})
    };
    
    handleConfirm(date) {
        console.warn("A date has been picked: ", date);
        this.setState({isDatePickerVisible: false})
    };


    changePage = (page) => {
        //alert(page)
    }
    
      render() {
        const { isLoading,isDatePickerVisible,loadingStats,soundAlerts,restaurant,acceptingOrders,ordersWithin,acceptTableService,acceptCollection   } = this.state;


        return (
          <Drawer
          ref={(ref) => { this.drawer = ref; }}
          content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
          onClose={() => this.closeDrawer()} >
                        
        <Container>
        <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
            <Content>
            <View>


          {(() => {

          if (isLoading) {
              return  (
                    <View style={{justifyContent:'center',flexDirection: 'column', flex:1, textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                  );
            } else {


              return  (
                <View>
                <Card transparent>
                    <CardItem>
                    <Left>
                    <Thumbnail source={{uri: restaurant.image}} />
                        <Body>
                            <Text> {restaurant.name}</Text>
                            <Text note>Order Console </Text>
                        </Body>
                    </Left>
                    <Right>

                    </Right>
                    </CardItem>
                </Card>

                    <View style={{flexDirection:'row',marginTop:15}}>
                                <View style={{flex:2,marginLeft:10}}>
                                    <Text style={{textAlign:'left'}}> Drink Service Available  </Text>

                                </View>
                                <View style={{flex:3,marginLeft:10,textAlign:'left',justifyContent:'flex-start'}}>


                           
                                </View>

                    </View>


                    <View style={{flexDirection:'row',marginTop:15}}>

                            <View style={{flex:2,marginLeft:10}}>
                          </View>
                                 <View style={{flex:3,marginLeft:10,marginBottom:10,textAlign:'left'}}>

                                    <Button onPress={() =>this.updateRange()}  info  rounded ><Text> Save Distance </Text></Button>

                                </View>

                 </View>


                <View>
                </View>
                </View>
                    )}
            })()}

        </View>
        </Content>
       <FooterBar />
       </Container>
       </Drawer>
        );
      }
    };


    const mapStateToProps = (state) => {
      return {
        table: state.table,
        cid: state.cid,
        bartoken: state.bartoken,
        restaurant: state.restaurant,
      }
  }

    const mapDispatchToProps = (dispatch) => {
      return {
          selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
          setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
          setCID: (token) => dispatch({ type: 'SET_CID_TOKEN', payload: token })
      }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(BarHours))
