import React, { Component } from 'react';
import { Container, Content,  Spinner, Card, CardItem, Left, Right, Body, Thumbnail,  Icon, Button, Text } from 'native-base';
import { withNavigation } from 'react-navigation'
import {  Linking,Platform,SafeAreaView, ScrollView,StyleSheet, RefreshControl,View, Image,AsyncStorage,StatusBar, Alert,Vibration} from 'react-native';
import {connect} from 'react-redux'
import * as Location from 'expo-location';
import HeaderBar from '../shared/Header.js'
import FooterBar from '../shared/Footer.js'
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';


import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';



class BarListing extends Component {
    static navigationOptions = {
        headerShown: false,
        title: 'Venue List'
      };
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          bartoken:'',
          navProps: props.navigation.state.params,
          cid:'',
          refreshing:false,
          lastFetched:'',
          fetching:false,
          locationDenied:false,
          table:[],
          isLoading: true,
          barcount:0,
          notification:{}
        };
      }

      closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
    

      readData = async (tokenName) => {
        try {
          const tokenValue = await AsyncStorage.getItem(tokenName)
          if (tokenValue !== null) {
            // Fetch the bartoken to see if there is an authenticated bar.
            if (tokenName === 'bartoken') { this.props.setBarToken(tokenValue);  }
            // Fetch the cid, the phone identifier/customer identifier for all users.
            if (tokenName === 'cid') {  this.props.setCID(tokenValue); this.setState({cid: tokenValue})  }
          }
        } catch (e) {
          alert('Failed to fetch the data from storage')
        }
      }

      async componentDidMount() {
        this.readData("cid");
        this.readData("bartoken");

        await Font.loadAsync({
          Roboto: require('native-base/Fonts/Roboto.ttf'),
          Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
          ...Ionicons.font,
        });
        if (this.props.bartoken) {
          this.setState({ isLoading: false });
        }

        this._getlocation();
        this.onReceivedListener = Notifications.addNotificationReceivedListener(this._handleNotification);
      }

      componentWillUnmount() {
        this.onReceivedListener.remove();
      }



    selectBar = (bar, type) => {
      this.props.selectBar(bar);
      this.props.setOrderType(type);
      if (this.props.restaurant.id){
        if (this.props.restaurant.id != bar.id) {
          this.props.emptyCart(bar);
        }
      }
        if (type == 'collection') {
          this.props.resetTable(bar.id);
          this.fetchDetails(bar.id,bar);
        } else {
           this.props.navigation.navigate('selectTable', {item: bar,type:'tableservice'});
       }
    }

    fetchBars = () => {
      var d = Date.now()
      if (! (this.state.lastFetched > (d - 9000) )) {
      this.setState({fetching: true}, function() {
          this.setState({ isLoading: true });
          this.setState({ refreshing: true });
          this.setState({ fetching: true });
          fetch('https://tabledrop.com/api/restaurant/?g=loc&lon=' + this.state.userLocation.coords.longitude + '&lat=' + this.state.userLocation.coords.latitude + '&cid=' + this.props.cid)
          .then((response) => response.json())
          .then((json) => {
            this.setState({ data: json });
            this.setState({ barcount: json.length });
            this.setState({ isLoading: false });
        })
          .catch((error) => console.error(error))
          .finally(() => {
            this.setState({ isLoading: false });
            this.setState({ refreshing: false });
            this.setState({ fetching: false });
            this.setState({lastFetched: Date.now()})
          });
    });
  } else {
    this.setState({ refreshing: false });
    //alert('blocked');
  }
  }

  fetchDetails(id,bar) {
    this.setState({ isLoading: true });
    fetch('https://tabledrop.app/api/drinkcategory/?restaurant=' + id)
    .then((response) => response.json())
    .then((json) => {
      this.props.setDrinksMenu(json)
  })
    .catch((error) => console.error(error))
    .finally(() => {
      this.fetchFoodDetails(id,bar)
    // this.setState({ isLoading: false });
    });
  }


  fetchFoodDetails(id,bar) {
    this.setState({ isLoading: true });
    fetch('https://tabledrop.app/api/foodcategory/?restaurant=' + id)
    .then((response) => response.json())
    .then((json) => {
      this.props.setFoodMenu(json)
  })
    .catch((error) => console.error(error))
    .finally(() => {
      this.setState({ isLoading: false });
      this.props.navigation.navigate('loadMenu', {item: bar,type:'collection'});

    })

  }


    _getlocation = async () => {
      const { status } = await Permissions.askAsync(Permissions.LOCATION)
      if (status !== 'granted') {
            this.setState({ locationDenied: true });
            this.setState({ isLoading: false });

            console.log('permission declined');
      } else {
            this.setState({ locationDenied: false });
      }
     if (Platform.OS === 'web') {
      const userLocation = await Location.getCurrentPositionAsync();
      this.setState({userLocation: userLocation}, function () {
        this.fetchBars();
       })
      }

      this.watchLocation = await Location.watchPositionAsync(
             {
                 //enableHighAccuracy: true,
                 distanceInterval: 15,
                 accuracy:6,
                 timeInterval: 11000
             },
             location => {
             let coords = location.coords;
             // this.props.getMyLocation sets my reducer state my_location
             this.setState({userLocation: location}, function () {
              this.fetchBars();
              //alert('u')

             })
           },
           error => console.log(error)
         );
    }


    _handleNotification = notification => {

      Vibration.vibrate()
      this.setState({ notification: notification }, function() {
        Alert.alert(
          "Order Update",
          this.state.notification.request.content.body,
          [
            { text: "OK", onPress: () => this.setState({notification:{}}) }
          ]

          );

      });


};



    componentWillUnmount() {
      this.watchLocation.remove();
   }



  _refreshingBars = async () => {
      this.setState({ refreshing: true });
        this._getlocation();
  }

  changePage = (page) => {
    //alert(page)
}


    render() {
      const { data, isLoading,barcount,refreshing,locationDenied } = this.state;

      const renderOrderButton = (item) => {
        let rendered = false;

        if (item.acceptTableService && (parseFloat(item.distance) < parseFloat(item.ordersWithin)) ) {
          return (

            <View style={{width:'100%'}}>


                    <Text style={{textAlign:'center',padding:15}}>Great! Let's order...</Text>



              <Button  primary full large style={styles.buttonGo} info onPress={() => this.selectBar(item,'tableservice')}>
                <Icon type="FontAwesome5" name="book-open" />
                <Text uppercase={false}>Order To My Table</Text>
              </Button>
              </View>
              )
        } else{
              return (
                <View style={{justifyContent:'center',textAlign:'center'}}>
                    <Text style={{textAlign:'center'}}>You are not at this venue, or table service isn't currently available at {item.name}</Text>
                </View>
            );
          }
        }


        const renderCollectionButton = (item) => {

          if (item.acceptCollection && (parseFloat(item.distance) < parseFloat(item.collectionsWithin)) ) {
            return (
                <Button success  full large style={styles.buttonCollect} info onPress={() => this.selectBar(item,'collection')}>
                  <Icon type="FontAwesome5" name="running" />
                  <Text uppercase={false}>Order A Collection</Text>
                </Button>
                )
          }
          }


        const renderText = (item) => {
          if (parseFloat(item.distance) < parseFloat(item.closeEnough) ) {
            return (
              <Text uppercase={false} style={{fontStyle: 'regular'}}>You are at {item.name} but they have not enabled TableDrop or are not accepting orders. Tell them about TableDrop today!</Text>
                )
          } else {
              return (
                <Text uppercase={false} style={{fontStyle: 'regular'}}>This venue has not enabled TableDrop or is not currently accepting orders. Tell them about TableDrop today!</Text>              );
          }
          }

    return (
      <Drawer
      ref={(ref) => { this.drawer = ref; }}
      content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
      onClose={() => this.closeDrawer()} >

      <Container>
      <StatusBar barStyle = "dark-content" hidden = {false} backgroundColor = "#1C2B48" translucent = {false}/>
      <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
      {(() => {
            if (isLoading) {
              return  (
                  <Content>
                    <View style={{justifyContent:'center', flex:1, flexDirection:'column',textAlign: 'center'}}>
                        <Spinner color='blue'/>
                    </View>
                    </Content>
                  );
            } else {
            return  (

                <ScrollView
                style={{marginTop:0}}
                refreshControl={
                  <RefreshControl refreshing={refreshing} onRefresh={this._refreshingBars} />
                }>

                  <View>
                  {(() => {
                      if ((barcount == 0) && !locationDenied) {
                          return (
                            <View style={{padding:20,textAlign: 'center', alignItems:'center'}}>
                                 <Image source={require('../assets/drinkpin.png')} style={{width:120, height:120 }}/>
                                <Text style={{ textAlign: 'center'}}>Sorry, we aren't in your area yet. There are no TableDrop venues nearby. </Text>

                            </View>);
                      }


                })()}

                  {(() => {
                      if (locationDenied) {
                          return (
                            <View style={{padding:20,textAlign: 'center', alignItems:'center'}}>
                                 <Image source={require('../assets/drinkpin.png')} style={{width:120, height:120 }}/>

                                <Text style={{ textAlign: 'center'}}>You have disabled Location services. Please enable in your App Settings so that TableDrop can find your closest venue.</Text>
                            </View>);
                      }
                })()}


                {data.map((item, index) =>
                  <Card>
                  <CardItem>
                  <Left>
                      <Thumbnail source={{uri: item.image}} />
                      <Body>
                      <Text>{item.name}</Text>
                      <Text note>{item.address2 ? (item.address2) : (item.town)}</Text>
                      </Body>
                  </Left>
                  <Right>

                  {item.distance < 1000 &&
                        <Text note>
                            {item.distance} meters
                        </Text>
                    }
                  {item.distance >= 1000 &&
                        <Text note>
                            {(item.distance/1000).toFixed(2)} km
                        </Text>
                    }

                  </Right>
                  </CardItem>
                  {(() => {

                      if (item.activeAccount && item.acceptingOrders) {
                        return (
                          <View>
                            <CardItem cardBody>
                            <Image
                                style={{height: 200, flex: 1}}
                                resizeMethod="scale"
                                resizeMode="cover"
                                source={{uri: item.image}}
                            />


                            </CardItem>
                            <CardItem>
                            <Body>
                            {renderOrderButton(item)}
                            {renderCollectionButton(item)}

                            </Body>
                            </CardItem>
                          </View>
                        );

                      } else {
                        return (
                        <View>
                          <CardItem cardBody>
                          <Image
                                style={{height: 200, opacity: 0.3, flex: 1}}
                                resizeMethod="scale"
                                resizeMode="cover"
                                source={{uri: item.image}}
                            />
                            </CardItem>
                            <CardItem>
                            <Body>
                                  {renderText(item)}

                            </Body>
                            </CardItem>
                          </View>
                        );
                      }

                  })()}

              </Card>

                )}
              </View>

              <View style={{textAlign:'center', marginTop:40, paddingLeft:40,paddingRight:30, marginBottom:10}}>
                     <Text style={{textAlign:'center'}}> Add your bar or venue by visiting www.TableDrop.com</Text>
               </View>


            </ScrollView>
      )}




    })()}


  <FooterBar />
  </Container>
</Drawer>
        )}




  }

   var styles = StyleSheet.create({
        buttonGo: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize:18,
            borderRadius:10

        },
        buttonCollect: {
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor:'#768fca',
          fontSize:18,
          marginTop:5,
          borderRadius:10

      }
    });

const mapStateToProps = (state) => {
  return {
      bartoken: state.bartoken,
      restaurant: state.restaurant,
      cid: state.cid,
      orderType:state.orderType

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      selectBar: (bar) => dispatch({ type: 'SELECT_RESTAURANT', payload: bar }),
      emptyCart: (bar) => dispatch({ type: 'EMPTY_CART', payload: bar }),
      setBarToken: (token) => dispatch({ type: 'SET_BAR_TOKEN', payload: token }),
      setCID: (token) => dispatch({ type: 'SET_CID_TOKEN', payload: token }),
      setDrinksMenu: (drinks) => dispatch({ type: 'SET_DRINKS_MENU', payload: drinks }),
      setFoodMenu: (foods) => dispatch({ type: 'SET_FOOD_MENU', payload: foods }),
      resetTable: (table) => dispatch({ type: 'RESET_TABLE', payload: table }),
      setOrderType: (type) => dispatch({ type: 'SET_ORDER_TYPE', payload: type }),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(BarListing))
