import React, { Component } from 'react';
import {  View, Text,TouchableOpacity, StyleSheet, Image,AsyncStorage  } from 'react-native';
import { Container,  Content, Icon, Spinner,  Button, Form, Input,  Item, Toast, Root} from 'native-base';
import {connect} from 'react-redux'
import { withNavigation } from 'react-navigation'


class CodeScreen extends Component {
    static navigationOptions = {
        headerShown: false,
        title: '',
      };
    constructor(props) {
        super(props);
        this.state = {
          phone: props.navigation.state.params,
          data: [],
          code:'',
          table:[],
          isLoading: true,
          verifyingNumber: false

        };
      }

      async storeToken(tokenName, tokenValue) {
        try {
           await AsyncStorage.setItem(tokenName, tokenValue);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }
      async getToken(tokenName) {
        try {
          let userData = await AsyncStorage.getItem(tokenName);
          let data = userData;
          console.log(data);
        } catch (error) {
          console.log("Something went wrong", error);
        }
      }


      sendSMS() {
         if (this.state.code) {
           this.setState({verifyingNumber: true})
           fetch('https://tabledrop.app/api/codeverify/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: this.state.code,
                phone: this.state.phone.phone,
                name: this.state.phone.name
            })
        })  .then((response) => response.json())
            .then((responseData) => {
                console.log("RESULTS HERE:", responseData)
                        this.setState({ 
                         isLoading: false
                    }, function(){
                        if (responseData.status == 'verified') {

                            // customer id responseData.id
                            this.storeToken("cid",responseData.id.toString());
                            this.storeToken("token",responseData.token.toString());
                            this.storeToken("phone",this.state.phone.phone);
                            this.storeToken("name",this.state.phone.name);
                            this.props.navigation.navigate('BarList')
                        } else {
                          Toast.show({
                            text: "Problem Validating Code. Please check and try again",
                            buttonText: "Okay",
                            duration:3000
                          })
                            this.setState({verifyingNumber: false})

                        }
                        //alert('r' + responseData);
                    });
            })
            .catch((error) =>{
                console.error(error);
                this.setState({verifyingNumber: false})

            })

            this.props.navigation.navigate('CodeScreen')
          } else {
            Toast.show({
              text: "Please enter the verification code. You should have received it by SMS",
              buttonText: "Okay",
              duration:3000
            })

          }
    }

      render() {
        console.log(this.state.phone.phone)
        return (
          <Root>

            <Container>
            <View style={styles.container}>
                <Image
                      source={require('../assets/logo.jpg')}
                      style={styles.logo}
                  />
                <Content>

                <Text style={styles.center}>Thank you, enter the 4 digit code we have just sent you.</Text>
                <Form style={styles.form}>
                    <Item rounded >
                    <Icon active name='call' style={styles.phone} />
                    <Input  returnKeyType={ 'done' }  onChangeText={(code) => this.setState({code})} keyboardType="numeric" placeholder='4 Digit Verification Code' style={styles.number}/>
                    </Item>



                    {(() => {
                        if ( this.state.verifyingNumber ) {
                            return (
                                <Spinner color='white' />
                            )
                            } else {
                            return(
                              <Button
                              onPress={() =>this.sendSMS()}
                             info large  style={styles.buttonGo} ><Text> Verify My Code </Text></Button>
                            )
                            }
                  })()}



                </Form>

                </Content>
            </View>
            </Container>
          </Root>
        );
      }
    };

    var styles = StyleSheet.create({
        logo: {
            width:300,
            height:60,
            marginBottom:50,
            marginTop:50,
        },
        form: {
            marginTop:30
        },

        phone: {
            color:'white'
        },
        number: {
            color:'white'
        },
        container: {
             flex: 1,
             justifyContent: 'center',
             alignItems: 'center',
             backgroundColor: '#1C2B48',
             color:'#fff',
             flexDirection: 'column',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            paddingTop:20,
            fontSize:18,

        },
        buttonGo: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            marginTop:40,
            fontSize:18,
            borderRadius:10

        }
    });


    const mapStateToProps = (state) => {
      return {
          table: state.table
      }
  }


    const mapDispatchToProps = (dispatch) => {
      return {
          selectTable: (table) => dispatch({ type: 'SELECT_TABLE', payload: table })
      }
  }
    export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(CodeScreen))
