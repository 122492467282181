import React, { Component } from 'react';
import { Container, Thumbnail,  List, ListItem, Card, Left, Spinner, Right, Body, CardItem } from 'native-base';
import FooterBar from '../shared/Footer.js'
import HeaderBar from '../shared/Header.js'
import Import from '../thirdparty/expo-stripe-checkout-sca/import';
import {Drawer} from 'native-base';
import Sidebar from '../shared/Sidebar';
import { withNavigation } from 'react-navigation'
import { StyleSheet, Text, View } from 'react-native'


class WebScreen extends Component {
    static navigationOptions = {
        headerShown: false,
        headerLeft: null
      };
    constructor(props) {
        super(props);
        this.state = {
          navProps: props.navigation.state.params,
          isLoading: false
        };
      }


      componentDidMount() {

        this.onfocusListener = this.props.navigation.addListener("willFocus", () => {
            this.updatePage();
         });



    }

    componentWillUnmount() {
        // Remove the event listener
        this.onfocusListener.remove();
      }


    updatePage(page = '') {
      let url;
      page = page || this.state.navProps.page;
      if (page == 'privacy') {
         url = 'https://tabledrop.com/mobileprivacy/';
      }
      if (page == 'terms') {
          url = 'https://tabledrop.com/mobileterms/';
      }
      if (page == 'venue') {
          url = 'https://tabledrop.com/mobileregister/';
      }
      if (page == 'feedback') {
          url = 'https://tabledrop.com/feedback/';
      }

      this.setState({url: url})
    }
 


    onNavigationStateChange(webViewState){
      console.log(webViewState.url)
      let url = webViewState.url;
      if (url.includes('paycomplete')) {
        this.setState({modalVisible:false})
        this.props.navigation.navigate('myOrders')
    }

    }

    changePage = (page) => {
        this.updatePage(page)
    }

    closeDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.close();
      };
      openDrawer = () => {
        this.drawer && this.drawer._root && this.drawer._root.open();
      };
    

      render() {
        const { data, isLoading,modalVisible,url,navProps } = this.state;
        

        if (isLoading) {
          return  (
                <Container style={{justifyContent:'center', textAlign: 'center'}}>
                <Spinner color='blue'/>
                </Container>
              );
        } else {

        return (
      <Drawer
      ref={(ref) => { this.drawer = ref; }}
      content={<Sidebar closeBar={this.closeDrawer} changePage={this.changePage} navigation={this.props.navigation}/>}
      onClose={() => this.closeDrawer()} >
          <Container>
          <HeaderBar  openDrawer={this.openDrawer.bind(this)}/>
            <Import.WebView
                source={{
                  uri: url
                }}
                useWebKit={true}
                javaScriptEnabled={true}
                scrollEnabled={true}
                bounces={false}
                originWhitelist={["*"]}
                startInLoadingState={true}
                onNavigationStateChange={(e) => this.onNavigationStateChange(e)}
                  style={{ marginTop: 0 }}
              />
            <FooterBar />
        </Container>
        </Drawer>
        );
     } // is loading
      }
    };


    export default withNavigation(WebScreen)
